import { SupportedNetworks, TokenBalance } from "types";
// import {
//     Multicall,
//     ContractCallResults,
//     ContractCallContext,
//     ContractCallReturnContext,
// } from 'ethereum-multicall';
// import Web3 from 'web3';
// import { NETWORKS } from "app-constants";
// import { BigNumber } from "ethers";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { decimalAmountToExactAmount } from "./math";

// const COMFORTABLE_LENGTH = 500 // 1000 is the best count

// type Values = {
//     [key in string]: ContractCallReturnContext
// }

// export const getTokenBalances = async (chainId: SupportedNetworks, allTokens: BaseToken[], accountAddress: string, web3: Web3 = new Web3(new Web3.providers.HttpProvider(NETWORKS[chainId].HTTP_PROVIDER_URL))): Promise<TokenBalance[]> => {
//     console.log('getTokenBalances before')
//     console.time('getTokenBalances before')
//     const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

//     const contractCallContext: ContractCallContext[] = allTokens.map((t) => (
//         {
//             reference: t.address,
//             contractAddress: t.address.toLowerCase(),
//             abi: [
//                 {
//                     "constant": true,
//                     "inputs": [
//                         {
//                             "name": "_owner",
//                             "type": "address"
//                         }
//                     ],
//                     "name": "balanceOf",
//                     "outputs": [
//                         {
//                             "name": "balance",
//                             "type": "uint256"
//                         }
//                     ],
//                     "payable": false,
//                     "stateMutability": "view",
//                     "type": "function"
//                 }
//             ],
//             calls: [{ reference: 'balanceOfCall_' + t.address, methodName: 'balanceOf', methodParameters: [accountAddress.toLowerCase()] }]
//         }
//     ))

//     const contractCallContexts: ContractCallContext[][] = [];
//     let count = 0;
//     do {
//         contractCallContexts.push(contractCallContext.slice(count, count + COMFORTABLE_LENGTH));
//         count += COMFORTABLE_LENGTH;
//     } while (count < contractCallContext.length);
//     console.timeEnd('getTokenBalances before')
//     console.log('ContractCallResults')
//     console.time('ContractCallResults')
//     const results: ContractCallResults[] = (await Promise.all(contractCallContexts.map((c) => multicall.call(c)))).flat(1);
//     console.timeEnd('ContractCallResults')
//     let values: Values = {}
//     for (let i = 0; i < results.length; i++) {
//         const re = results[i];
//         values = {
//             ...values,
//             ...re.results,
//         }
//     }
//     const balances: TokenBalance[] = allTokens.map((tt) => {
//         const callsReturnContext = values[tt.address]?.callsReturnContext
//         const hexBalance: string = callsReturnContext?.[0].returnValues?.[0]?.hex || '0x0';
//         const balance = BigNumber.from(hexBalance).toString();
//         const floatBalance = decimalAmountToExactAmount(balance, tt.decimals)
//         return {
//             ...tt,
//             balance: floatBalance
//         }
//     })
//     return balances;
// }

export const getTokenBalancesWithMorails = async (
  address: string,
  chainId: SupportedNetworks
): Promise<TokenBalance[]> => {
  let chain: EvmChain;
  switch (chainId) {
    case SupportedNetworks.bsc:
      chain = EvmChain.BSC;
      break;
    case SupportedNetworks.bsctestnetwork:
      chain = EvmChain.BSC_TESTNET;
      break;
    case SupportedNetworks.mainnet:
      chain = EvmChain.ETHEREUM;
      break;
    case SupportedNetworks.sepolia:
      chain = EvmChain.SEPOLIA;
      break;
    default:
      chain = EvmChain.BSC;
      break;
  }
  const response = await Moralis.EvmApi.token.getWalletTokenBalances({
    address,
    chain,
  });
  const jsonData = response.toJSON();

  const result: TokenBalance[] = jsonData
    .filter(
      (t) =>
        t.possible_spam === false && t.symbol !== null && t.decimals !== null
    )
    .map((t) => {
      return {
        address: t.token_address,
        balance: decimalAmountToExactAmount(t.balance, t.decimals),
        decimals: t.decimals.toString(),
        name: t.name,
        symbol: t.symbol,
      };
    });
  return result;
};
