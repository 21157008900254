import styled from 'styled-components'
import { AppScrollbarCSS } from './others/styled'

export const SOffcanvas = styled.div`
  width: auto;
  margin-top: 127px;

  & .offcanvas-body {
    ${AppScrollbarCSS}
  }
`
