import BondSubgraph from 'helper/subgraphs/BondSubgraph'
import { RootState } from 'redux/store'
import { AppThunkAction, BondData, BondInfo, SupportedNetworks } from 'types'
import { getBond } from 'helper/apis/bond-server-api'
import { Actions, ActionTypes } from './types'
import { adjustBondParams } from 'helper'

type BondListAppThunkAction<
  R, // Return type of the thunk function
> = AppThunkAction<R, RootState, null, Actions>

export const fetchBond = (appChainId: SupportedNetworks, bondId: string): BondListAppThunkAction<Promise<void>> => async (dispatch) => {
  let bonds: Array<BondData> = []
  dispatch({
    type: ActionTypes.IS_LOADING,
    payload: true,
  })
  // get bonds
  try {
    const bondSubgraph = new BondSubgraph(appChainId)
    const res = await bondSubgraph.getBonds({
      id: bondId
    })
    if (res.data === null || !Array.isArray(res.data.bonds)) {
      throw new Error('Something went wrong. Bond Data is null or Bond list is not array.')
    }
    bonds = res.data?.bonds as Array<BondData>
    bonds = bonds.map((bond) => adjustBondParams(bond))
  } catch (error) {
    console.error(error)
  }
  try {
    // get project informations
    const res = await getBond({ bondId: bondId, networkId: appChainId })
    const info = res.data.bond as BondInfo
    bonds[0].info = info
  } catch (error) {
    console.error(error)
  }
  dispatch({
    type: ActionTypes.FETCH_BOND_DETAIL,
    payload: bonds[0],
  })
  dispatch({
    type: ActionTypes.IS_LOADING,
    payload: false,
  })
}
