import { useEffect, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { getAppTheme } from 'Theme/theme'
import { appThemeConfigs } from 'config/app-config'
import { useAppDispatch } from 'redux/hooks'
import { setThemeConfig } from 'redux/reducers/globals/globalActions'
import useThemeConfigName from 'hooks/useThemeConfigName'

function AppThemeProvider({ children }: {
    children: JSX.Element | JSX.Element[] | any
}): JSX.Element {
    const dispatch = useAppDispatch()
    const themeConfigName = useThemeConfigName();

    useEffect(() => {
        dispatch(setThemeConfig(appThemeConfigs[themeConfigName]))
    },[themeConfigName])

    const selectedThemeName = useMemo(() => {
        return getAppTheme(appThemeConfigs[themeConfigName].themeName)
    }, [themeConfigName])

    return (
        <ThemeProvider theme={selectedThemeName}>{children}</ThemeProvider>
    )
}

export default AppThemeProvider