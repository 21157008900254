import React, { useEffect, useCallback, useMemo, useLayoutEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import appSocket from "socket/socket";
import Gs from "./Theme/globalStyles";
import {
  setAvailableTokens,
  setAccount,
  setAppChain,
  loadUserTokensWithBalance,
} from "./redux/reducers/globals/globalActions";
import {
  initSimplePrices,
  setSimplePrices,
} from "./redux/reducers/coingecko/coingeckoAction";
import ConnectWallet from "Component/popup/ConnectWallet";

import useWallet from "hooks/useWallet";
import TransactionModals from "Component/popup/transactions/transaction-popups";
import { useAppChain, useAvailableTokens, useTokenListStorage } from "hooks";
import { createClient, getTokensQL } from "helper/graphql/index";
import { AppContext } from "Contexts";

import FailedMessagePopup from "Component/popup/FailedInfoPopup";
import AppTooltip from "Component/AppTooltip";

import TelegramCallback from "Pages/TelegramCallback";

import ComponentExplorer from "Pages/ComponentExplorer";
import ErrorBoundary from "Component/ErrorBoundary";
import { DEREX_SUBGRAPH, NETWORKS } from "./app-constants";
import { useAppDispatch } from "redux/hooks";
import { ReducerSimplePrices, SupportedNetworks } from "types";
import LPInsureRouter from "routers/LPInsureRouter";
import DerexAndIBORouter from "routers/Derex-IBORotuer";
import { useCustomTokens } from "hooks/useCustomTokens";
import { TokenPrices, getTokenPrices } from "helper/apis/bond-server-api";
import ComingSoon from "Pages/ComingSoon";
import useAppDebounceEffect from "hooks/useAppDebounceEffect";
import IBOTwitterCallback from "Pages/IBO/components/IBOTwitterCallback";
import { navs } from "Component/Headers/AppHeader";
import { smartChainQuery } from "helper";

function App(): JSX.Element {
  const history = useHistory();
  const { active, switchNetwork, chainId, account } = useWallet();
  const dispatch = useAppDispatch();
  const { appChainId } = useAppChain();
  const { tokens: customTokens } = useCustomTokens(appChainId);
  // eslint-disable-next-line
  const { setVisible } = useTokenListStorage(appChainId);
  const [availableTokens, allTokens] = useAvailableTokens();

  const isWrongNetwork: boolean = useMemo(() => {
    const status = active && chainId && chainId !== appChainId;
    return Boolean(status);
  }, [active, appChainId, chainId]);

  const handleTokenPriceUpdate = useCallback(
    (_tokenPrices: TokenPrices[], isInit = false) => {
      const values: ReducerSimplePrices = {};
      _tokenPrices.forEach((token) => {
        values[token.address.toLowerCase()] = {
          address: token.address,
          name: token.symbol,
          symbol: token.symbol,
          priceUSD: String(token.usd),
          usd_24h_change: String(token.priceChange.h24) || "0",
        };
      });
      if (isInit) {
        dispatch(initSimplePrices(values));
      } else {
        dispatch(setSimplePrices(values));
      }
    },
    []
  );

  const loadTokenPrice = useCallback(async () => {
    if (availableTokens.length > 0) {
      // TODO need to update with Mark's token api
      // let symbols = [...availableTokens.map((t) => t.symbol), ...allTokens.map((t) => t.symbol)]
      let tokenIds: string[] = [...availableTokens.map((t) => t.address)]
        .join(",")
        .toLowerCase()
        .split(",");
      tokenIds = tokenIds.filter((s, i) => {
        return tokenIds.findIndex((s1) => s1 === s) === i;
      });

      const res = await getTokenPrices({
        networkId: appChainId,
        tokenIds: tokenIds,
      });
      handleTokenPriceUpdate(res.data.tokenPrices, true);
    }
  }, [availableTokens, appChainId, handleTokenPriceUpdate]);

  // load pairs
  useEffect(() => {
    const chainId = appChainId || SupportedNetworks.mainnet;
    const apiClient = createClient(DEREX_SUBGRAPH[chainId]);
    apiClient
      .query({
        query: getTokensQL,
      })
      .then((res) => {
        dispatch(setAvailableTokens(res.data?.tokens || []));
      })
      .catch((error) => {
        console.log(error);
        // dispatch(setAvailableTokens([]))
      });

    dispatch(setAppChain(appChainId));
  }, [appChainId]);

  useEffect(() => {
    if (isWrongNetwork) {
      switchNetwork(appChainId);
    }
  }, [active]);

  useEffect(() => {
    const fetchChainId = async () => {
      if (window.ethereum) {
        window.ethereum.on("chainChanged", async () => {
          try {
            const chainId = await window.ethereum.request({
              method: "eth_chainId",
            });
            const decimal = parseInt(chainId, 16);
            console.log("chainChanged: ", decimal);
            let search = history.location.search;
            search = smartChainQuery(search, decimal);

            history.push({
              pathname: history.location.pathname,
              search: search,
            });
          } catch (error) {
            console.error("Error fetching chain ID:", error);
          }
        });
        window.ethereum.on("accountChanged", async () => {
          try {
            const chainId = await window.ethereum.request({
              method: "eth_chainId",
            });
            const decimal = parseInt(chainId, 16);
            console.log("chainChanged: ", decimal);
            let search = history.location.search;
            search = smartChainQuery(search, decimal);

            history.push({
              pathname: history.location.pathname,
              search: search,
            });
          } catch (error) {
            console.error("Error fetching chain ID:", error);
          }
        });
      }
    };
    fetchChainId();
  }, [window.ethereum]);

  useAppDebounceEffect(
    () => {
      loadTokenPrice();
    },
    300,
    [loadTokenPrice]
  );

  // store variables in globals reducer
  useEffect(() => {
    if (account) {
      dispatch(setAccount(account));
    } else {
      dispatch(setAccount(""));
    }
  }, [account]);

  // fetch balance with tokens for user
  useAppDebounceEffect(
    () => {
      const _tokens = allTokens.concat(customTokens);
      const network = NETWORKS[appChainId];
      account &&
        _tokens.length > 0 &&
        dispatch(
          loadUserTokensWithBalance(
            appChainId,
            _tokens,
            network.W_TOKEN_ADDRESS,
            account
          )
        );
    },
    300,
    [allTokens, customTokens, appChainId, account]
  );

  useLayoutEffect(() => {
    if (
      window.location.hostname === process.env.REACT_APP_LPINSURE_APP &&
      location.pathname.search(/\/lp/gi) === -1
    ) {
      history.push(`/lp${history.location.search}`);
    }
  }, []);

  useEffect(() => {
    appSocket.connect();
    function onConnectEvent() {
      console.log("Socket connected!");
    }
    appSocket.on("connect", onConnectEvent);
    return () => {
      appSocket.disconnect();
      appSocket.off("connect", onConnectEvent);
    };
  }, []);

  useEffect(() => {
    const onTokenPriceUpdateEvent = (_tokenPrices: TokenPrices[]) => {
      console.log("TokenPriceUpdate!");
      handleTokenPriceUpdate(_tokenPrices);
    };
    const eventName = `TokenPriceUpdate/${appChainId}`;
    appSocket.on(eventName, onTokenPriceUpdateEvent);
    return () => {
      appSocket.off(eventName, onTokenPriceUpdateEvent);
    };
  }, [appChainId, handleTokenPriceUpdate]);

  return (
    <AppContext.Provider value={{ isWrongNetwork: isWrongNetwork }}>
      <ErrorBoundary>
        <section className="MainBox clearfix z-index-1">
          <Gs.GlobalStyle />
          <Gs.MainBox navs={navs}>
            <Switch>
              <Route
                path="/telegram/callback"
                component={TelegramCallback}
                exact
              />
              <Route
                path="/ibo-auth/twitter/callback"
                component={IBOTwitterCallback}
                exact
              />
              <Route
                path="/component/explorer"
                component={ComponentExplorer}
                exact
              />
              <Route path="/lp" component={LPInsureRouter} />
              <Route path="/coming-soon" component={ComingSoon} />
              {/* /swap, /ibo, /reimbursement, /roi-booster */}
              <Route path={"/"} component={DerexAndIBORouter} />
            </Switch>
          </Gs.MainBox>
        </section>
        <ConnectWallet />
        <FailedMessagePopup />
        <TransactionModals.Loading />
        <AppTooltip />
      </ErrorBoundary>
    </AppContext.Provider>
  );
}

export default App;
