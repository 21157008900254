import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { BOND_MAIN_CONTRACTS, NETWORKS } from "app-constants";
import { SupportedNetworks } from "types";
import BondImplementationAbi from 'Assets/Abi/bond-implementation-abi.json'

type UserAmount = {
    principalAmount: string | number
    availableAmount: string | number
}

export const getUserAmount = async(appChainId: SupportedNetworks, classId: string, nonceId: string, account: string): Promise<UserAmount> =>{
    const currentBondContract = BOND_MAIN_CONTRACTS[appChainId]
    const httpProviderUrl = NETWORKS[appChainId].HTTP_PROVIDER_URL
    const w3 = new Web3(httpProviderUrl)
    const bondInstance = new w3.eth.Contract(BondImplementationAbi as AbiItem[], currentBondContract)
    const result = await bondInstance.methods.getUserAmount(classId, nonceId, account).call()
    return result
}

type UserAmounts = {
    principalAmount: string[] | number[]
    availableAmount: string[] | number[]
}
export const getUserAmounts = async(appChainId: SupportedNetworks, classIds: string[], nonceIds: string[], account: string): Promise<UserAmounts> =>{
    const currentBondContract = BOND_MAIN_CONTRACTS[appChainId]
    const httpProviderUrl = NETWORKS[appChainId].HTTP_PROVIDER_URL
    const w3 = new Web3(httpProviderUrl)
    const bondInstance = new w3.eth.Contract(BondImplementationAbi as AbiItem[], currentBondContract)
    const result = await bondInstance.methods.getUserAmounts(classIds, nonceIds, account).call()
    return result
}
