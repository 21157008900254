import { LOCAL_STORAGE_ITEM_NAMES } from "app-constants";
import useWallet from "hooks/useWallet";
import React, { ReactNode, useEffect, useState } from "react";
import { WalletConnectorTypes } from "types";

const Web3ConnectionWrapper = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element | null => {
  const { connectWallet } = useWallet();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const savedWalletType = localStorage.getItem(
      LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE
    );

    if (savedWalletType) {
      connectWallet(parseInt(savedWalletType) as WalletConnectorTypes).finally(
        () => setChecked(true)
      );
    } else {
      setChecked(true);
    }
  }, [connectWallet]);

  if (checked) {
    return children;
  }

  return null;

  return children;
};

export default Web3ConnectionWrapper;
