import { AppFontColors, AppFontSizes, DefaultTheme } from "styled-components"

export const fontSizes: AppFontSizes = {
  xs: '10px',
  xxs: '11px',
  sm: '12px',
  md: '15px',
  '2md': '18px',
  lg: '20px',
  xl: '25px',
  '2xl': '30px',
  '3xl': '35px',
  '4xl': '40px',
  '5xl': '50px',
  '6xl': '56px',
}

export const fontColors: AppFontColors = {
  'dark-1': '#0c0d11',
  'disabled': '#414342',
  muted: '#76797e',
  secondary1: '#dfaa01',
}

export const AppDefaultColors: DefaultTheme = {
  // text colors
  colorWhite: '#fff',
  // background colors
  headbg01: '#303239',
  headbg02: '#25282f',
  bodybg01: '#2c2f36',
  bodybg02: '#000000',
  bodybg03: '#16191e',
  bodybg04: '#090a0d',
  bodybg05: '#292c32',
  bodybg06: '#2a2a2a',
  bodybg09: '#34383f',
  bodybg010: '#020202',
  bodybg011: '#212429',
  bodybg012: '#303239',
  bodybg013: '#5d6268',
  bodybg014: 'rgb(22, 25, 30)',
  bodybg015: '#1f2227',
  colorPrimary: '#8be05a',
  colorPrimary01: '#a0ef72',
  colorPrimary02: '#a0ef72b5',
  colorPrimary03: '#8db610',
  colorPrimary04: '#3d4d1b',
  colorPrimaryHover: '#67bf35',
  colorSecondary: '#00acee',
  colorLightGrey: '#76797e',
  colorLightGrey01: '#252a30',
  greyBorder: '#545861',
  grayBorder2: '#353840',
  grayBorder3: '#5a5e67',
  grayBorder4: '#5a5e67',
  placeHolderColor: '#393d46',
  colorRed: '#b92c1a',
  colorRedHover: '#9b1c1e',
  colorPlus: '#565a69',
  colorDarkGrey: '#5d6168',
  thumbColor: '#6a6d74',
  colorGrey: '#ababab',
  colorGrey3: '#9a9a9a',
  colorGrey4: '#282b31',
  colorGrey5: '#aaaaaa',
  colorGrey6: '#878787',
  colorGrey8: '#1e2128',
  colorGrey9: '#5e5c5c',
  colorGrey10: '#8e9295',
  colorGrey11: '#3a3e47',
  colorGrey12: '#393d48',
  boxShadowOne: 'rgb(0 0 0 / 10%)',
  colorBlue01: '#1f21dd',
  colorBlue02: '#28a4e4',
  networkIconBg: '#303a4c',
  pageBgColor: '#1f2229',
  pageBgImage: '/images/shield-bg.png',
  selectedTokenBg: '#25282f',
  twitterButtonHoverColor: '#00acee',
  twitterButtonBoxShadow: 'unset',
  twitterButtonColor: 'unset',
  twitterButtonTextColor: 'white',
  fontSize: fontSizes,
  fontColors: fontColors,
}

type AppThemes = {
  dark: DefaultTheme
  lpInsure: DefaultTheme
}

const appThemes: AppThemes = {
  dark: {
    ...AppDefaultColors,
  },
  lpInsure: {
    ...AppDefaultColors,
    colorPrimary: '#f92289',
    colorPrimary01: '#ff3495',
    colorPrimary02: '#f92289',
    colorPrimary03: '#f92289',
    colorPrimary04: '#6b234b',
    colorPrimaryHover: '#d31a73',
    colorLightGrey: '#9a9a9a',
    twitterButtonBoxShadow: '0 0 20px rgba(0, 172, 238, 0.5)',
    twitterButtonColor: '#123648',
    twitterButtonHoverColor: '#123648',
    twitterButtonTextColor: '#01a0db',
    pageBgImage: '/images/lp-insure/lp-logo-no-text-svg.png',
  }
}

export const getAppTheme = (themeName?: keyof AppThemes): DefaultTheme => {
  if(themeName) {
    return appThemes[themeName]
  } else {
    return appThemes.dark
  }
}
