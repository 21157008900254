import { SmartContractConfig, SupportedNetworks } from "types";

export const LP_INSURE_PROXY_FACTORY: SmartContractConfig = {
  [SupportedNetworks.mainnet]: "TODO",
  [SupportedNetworks.bsc]: "TODO",
  [SupportedNetworks.bsctestnetwork]:
    "0xdcca1eab5aa0c46caecad7b330ece8e9854698cf",
  [SupportedNetworks.sepolia]: "TODO",
};

export const LP_INSURE_MAIN_CONTRACT: SmartContractConfig = {
  [SupportedNetworks.mainnet]: "TODO",
  [SupportedNetworks.bsc]: "TODO",
  [SupportedNetworks.bsctestnetwork]:
    "0x92ae4e2d261fbe88830ab848c1ef9caf3c15b1a7",
  [SupportedNetworks.sepolia]: "TODO",
};
