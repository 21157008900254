import { useCallback, useEffect } from "react";
import Axios from "axios";

import { LOCAL_STORAGE_ITEM_NAMES, URL_ARRAY } from "app-constants";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { TokenListSelector } from "redux/selectors";
import {
  APITokenObject,
  AppToken,
  AppTokenListData,
  SupportedNetworks,
  TokenListItem,
  TokenResponse,
} from "types";
import {
  setItem,
  setItems,
  updateItem,
} from "../redux/reducers/tokenList/tokenActions";
import { saveStateToLocalStorage } from "helper/localStorage";

type UseStorageReturnType = {
  setVisible: (index: number, enabled?: boolean) => void;
  removeItem: (index: number) => void;
  addItem: (item: TokenListItem, chainId: SupportedNetworks) => void;
};

export const useTokenListStorage = (
  chain: SupportedNetworks
): UseStorageReturnType => {
  const dispatch = useAppDispatch();
  const { items, itemData } = useAppSelector(TokenListSelector); // coin list

  // get items saved or save and return if does not exist
  const getCoinList = useCallback(() => {
    const _coinList =
      localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.COIN_LIST) || "";
    let parsed;
    try {
      parsed = JSON.parse(_coinList);
      const networkName = Object.keys(parsed)[0];
      const firstData = parsed[networkName][0].data;
      if (!firstData) throw new Error("Incorrect format!");
      return parsed;
    } catch (e) {
      localStorage.setItem(
        LOCAL_STORAGE_ITEM_NAMES.COIN_LIST,
        JSON.stringify(URL_ARRAY)
      );
      return URL_ARRAY;
    }
  }, []);

  const refreshToken = useCallback(
    async (_chain: SupportedNetworks, _item: TokenListItem, index: number) => {
      const chainId = _chain;
      try {
        const { data } = await Axios.get(_item.tokenListUrl);
        const res: TokenResponse = data as TokenResponse;
        const tokensInfoItem: AppTokenListData = res as AppTokenListData;
        if (Array.isArray(res.tokens)) {
          res.tokens = (res?.tokens || [])
            .filter((el) => {
              const cId = parseInt(String(el.chainId)) as SupportedNetworks;
              return cId === chainId;
            })
            .map(
              (el) =>
                ({
                  ...el,
                  image: el.logoURI,
                  value: el.address,
                  label: el.symbol,
                } as APITokenObject)
            );
        } else {
          // if res.tokens is object
          const tokens: AppToken[] = [];
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(res.tokens)) {
            tokens.push({
              // @ts-ignore
              chainId: chainId,
              ...value,
              image: value.logoURI ? value.logoURI : "",
              label: value.symbol,
            });
          }
          tokensInfoItem.tokens = tokens.sort((a, b) =>
            a.symbol.toLowerCase() > b.symbol.toLowerCase() ? 1 : -1
          );
        }
        dispatch(setItem(index, tokensInfoItem));
      } catch (e) {
        console.log("Error: ", e);
        dispatch(setItem(index, {} as AppTokenListData));
      }
    },
    []
  );

  const setVisible = useCallback(
    (index: number, enabled = false) => {
      if (ChannelSplitterNode) {
        const _coinlist = getCoinList();
        _coinlist[chain][index].enabled = enabled;
        try {
          localStorage.setItem(
            LOCAL_STORAGE_ITEM_NAMES.COIN_LIST,
            JSON.stringify(_coinlist)
          );
        } catch (error) {
          localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.COIN_LIST);
          window.location.reload();
        }
      }
      dispatch(updateItem(index, { enabled: enabled }));
    },
    [getCoinList, chain]
  );

  const removeItem = useCallback(
    (index: number) => {
      const _coinlist = getCoinList();
      const list = _coinlist[chain];
      list.splice(index, 1);
      _coinlist[chain] = list;
      try {
        saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.COIN_LIST, _coinlist);
        // @ts-ignore TODO need to check types
        dispatch(removeItem(index));
      } catch (error) {
        localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.COIN_LIST);
        window.location.reload();
      }
    },
    [chain, getCoinList]
  );
  const addItem = useCallback(
    (item: TokenListItem, chainId: SupportedNetworks) => {
      const _coinlist = getCoinList();
      let list = _coinlist[chainId];
      list = [...list, item];
      _coinlist[chainId] = list;
      try {
        localStorage.setItem(
          LOCAL_STORAGE_ITEM_NAMES.COIN_LIST,
          JSON.stringify(_coinlist)
        );
        dispatch(setItems(list));
      } catch (error) {
        localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.COIN_LIST);
        window.location.reload();
      }
    },
    [getCoinList]
  );

  useEffect(() => {
    const list = getCoinList();
    const _list = list[chain] || [];
    dispatch(setItems(_list));
  }, [chain, getCoinList]);

  useEffect(() => {
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      if (!itemData[i]) {
        refreshToken(chain, element, i);
      }
    }
  }, [chain, items, itemData, refreshToken]);

  return { setVisible, removeItem, addItem };
};
