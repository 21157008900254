import MainBox from 'Component/MainBox'
import styled, { createGlobalStyle, css } from 'styled-components'

type CSSHighlightedBorderRightProps = {
  height?: string
}

const getHighlightedBorderRight = ({ height = '100%' }: CSSHighlightedBorderRightProps) => {
  return css<CSSHighlightedBorderRightProps>`
  position: relative;
  &:after {
    content: '';
    width: 2px;
    height: ${height};
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, #000000 50%,#292c32 50%);
  }
`
}

const GlobalStyle = createGlobalStyle`
  a {
    color:${(props) => props.theme.colorWhite};
  }
  a:hover {
    color:${(props) => props.theme.colorPrimary};
  }
  body {
    margin: 0;
    padding: 0; 
    background-color: ${(props) => props.theme.bodybg015}; 
    color: ${(props) => props.theme.colorWhite}; 
  }
  .orangeColor{color:${(props) => props.theme.colorPrimary} !important; }
  .text-primary {
    color:${(props) => props.theme.colorPrimary} !important;
  }
  .hover\\:text-white:hover {
    color: white !important;
  }

  .text-primary-2{color:${(props) => props.theme.colorPrimary03} !important; }
  .text-secondary{color:${(props) => props.theme.colorBlue01} !important; }
  .text-secondary1{color:${(props) => props.theme.fontColors.secondary1} !important; }
  .text-danger{color:${(props) => props.theme.colorRed} !important; }
  .text-muted{
    color: ${(props) => props.theme.fontColors.muted} !important;
  }
  .text-gray-3{color:${(props) => props.theme.colorGrey3} !important; }
  .text-gray-4{color:${(props) => props.theme.colorGrey4} !important; }
  .text-dark-1{color:${({ theme }) => theme.fontColors['dark-1']} !important; }
  .text-disabled{color:${({ theme }) => theme.fontColors.disabled} !important; }
  .p{color: ${({ theme }) => theme.colorPrimary} !important; }
  input:invalid,
  textarea:invalid {
    color: ${({ theme }) => theme.colorRed};
  }
  .myTip{ max-width:300px; font:400 14px/22px 'IBM Plex Mono', arial !important; color:#fff !important;}
  .helpIco{ 
    font-size: 12px;
    position:relative; 
    margin-left: 5px; 
    top:-7px;
  }

  img{ max-width:100%; height:auto;}

    @media (max-width:767px){
      .MainBox{  background-image:none; }
      
     }


  .MainBox.noBG{background-image:none;}

  input{ outline:none;}
  img{ max-width:100%; height:auto;}
  button{
    background:transparent; outline:none; border:0;
  }
 
  .redColor{color:#c32b2d!important;} 

.wb-Table04{margin:30px 0 60px 0;width:100%;}
.wb-Table04 table tr td{border-bottom:1px solid #393d46;font-weight:500;color:#fff;font-size:12px;}
.wb-Table04 table tr th{font-weight:500;font-size:14px;color:#9a9a9a;text-align:left;border-top:2px solid #393d46;border-bottom:2px solid #393d46;}
.wb-Table04 table tr td a{color:#4848ff;}
.wb-Table04 table tr td a:hover{text-decoration:underline;}
.wb-Table04 table tr td a .fas{position:relative;top:3px;}
.wb-Table04 .wbt4Title01{color:#fff;font-size:24px;font-weight:700;width:50px;}
.wb-Table04 table tr td.tdBRleft,.wb-Table04 table tr th.tdBRleft{border-left:1px solid #393d46;}
.wb-Table04 table tr td.grayText{color:#9a9a9a;}

.sb-BorRight{ border-right:1px solid #393d46; } 

.collapse-css-transition { transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1); }

.md-checkbox{position:relative;margin:10px 0; width:100%;}
.md-checkbox label{cursor:pointer;font-size:12px;margin-left:29px;display:inline-block; font-size:16px;font-weight:700;margin-left:0;margin-right:30px;}
.md-checkbox label:before,.md-checkbox label:after{content:"";position:absolute;left:0;top:0;}
.md-checkbox label:before{width:20px;height:20px;background:#fff;border:2px solid rgba(0, 0, 0, 0.54);border-radius:2px;cursor:pointer;transition:background .3s;}
.md-checkbox input[type="checkbox"]{outline:0;margin-right:10px;position:absolute;}
.md-checkbox input[type="checkbox"]:checked + label:before{background:#337ab7;border:none;}
.md-checkbox input[type="checkbox"]:checked + label:after{transform:rotate(-45deg);top:5px;left:4px;width:12px;height:6px;border:2px solid #fff;border-top-style:none;border-right-style:none;}
.md-checkbox:not(:last-child){padding-right:10px;}
.md-checkbox input{background:none!important;border:none!important;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.md-checkbox label{font-size:16px;font-weight:700;margin-left:0;margin-right:30px;}
.md-checkbox label:before,.md-checkbox input[type="checkbox"]:checked + label:before{border:2px solid rgba(255, 255, 255, 0.10);background:rgba(255, 255, 255, 0.05);left:auto;right:0;}
.md-checkbox input[type="checkbox"]:checked + label:after{transform:rotate(-45deg);top:1px;left:auto;width:18px;height:9px;border:3px solid #faba37;border-top-style:none;border-right-style:none;right:-4px;}
.md-checkbox input[type="checkbox"]:checked + label{color:#fff;}
input{border-radius:4px;}
.md-checkbox:nth-child(01) { margin-top:20px;}

.disaBled{ opacity:0.7; pointer-events:none; color:#4B4E56 !important; border-color:#4B4E56 !important;}


.md-checkbox.inline{ width:auto; margin:0px; }
.md-checkbox.leftS label{ margin-right:0px; margin-left:30px; }
.md-checkbox.leftS label:before,.md-checkbox.leftS input[type="checkbox"]:checked + label:before{ left:0px; right:auto;}
.md-checkbox.leftS input[type="checkbox"]:checked + label:after{right:auto; left:5px;}

.hover\\:border-highlight-shadow-primary:hover {
  border-color: ${(props) => props.theme.colorPrimary};
  box-shadow: ${(props) => props.theme.colorPrimary} 0px 0px 20px 1px;
}

.border-highlight-shadow{
  &-primary {
    border-color: ${(props) => props.theme.colorPrimary};
    box-shadow: ${(props) => props.theme.colorPrimary} 0px 0px 10px 1px;
  }
  &-gray {
    border-color: ${(props) => props.theme.colorGrey};
    box-shadow: ${(props) => props.theme.colorGrey} 0px 0px 10px 1px;
    &-1 {
      box-shadow: ${(props) => props.theme.colorGrey} 0px 0px 3px 0px;
    }
  }
}
  .fs-{
    ${(props) =>
    Object.entries(props.theme.fontSize).map(
      ([key, value]) => `
      &${key} {
        font-size: ${value} !important;
      }
    `
    )}
  }
  ${(props) =>
    Object.entries(props.theme)
      // eslint-disable-next-line
      .filter(([key, value]) => typeof value === 'string')
      .map(
        ([key, value]) => typeof value === 'string' &&
          `.bg-${key} {
              background-color: ${value} !important;
            }
            .text-${key} {
              color: ${value} !important;
            }
            .hover\\:text-${key} {
              &:hover{
                color: ${value} !important;
              }
            }
            .hover\\:border-highlight-${key}:hover{
              box-shadow: ${value} 0px 0px 22px 0px;
            }
            .border-color-${key} {
              border-color: ${value} !important;
            }
        `
      )}
  .highlighted-border-bottom{
    position: relative;
    &:after {
      content: '';
      width: inherit;
      height: 2px;
      position: absolute;
      background: linear-gradient(#000000 50%,#292c32 50%);
    }
  }
  .highlighted-border-right {
    ${getHighlightedBorderRight({ height: '100%' })}
    &\\:h-80p {
      ${getHighlightedBorderRight({ height: '80%' })}
    }
    &\\:h-82p {
      ${getHighlightedBorderRight({ height: '82%' })}
    }
    &\\:h-85p {
      ${getHighlightedBorderRight({ height: '85%' })}
    }
  }
  .bg-linear-1 {
    background: linear-gradient(90deg, #1f2228 0%, #16191e 50%, #1f2228 100%);
  }

  .bg-primary {
    background-color: ${({ theme }) => theme.colorPrimary} !important;
  }
  .bg-primary-1 {
    background-color: ${({ theme }) => theme.bodybg03} !important;
  }
  .bg-secondary {
    background-color: ${({ theme }) => theme.greyBorder} !important;
  }
  .bg-img-primary{
    background: url('/images/shield-bg.png') no-repeat center 20px;
  }
  .text-shadow{
    &-primary{
      text-shadow: 0 0 9px ${(props) => props.theme.colorPrimary02};
    }
  }
  .hover\\:{
    &-text-none {
      color: none !important;
    }
    &text-danger:hover{
      color:${(props) => props.theme.colorRed} !important;
    }
  }
  .rdt_TableCell {
    & button {
      color: ${({ theme }) => theme.colorLightGrey};
    }
  }
`

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
  max-width: 1420px;
`
interface IGs {
  GlobalStyle: typeof GlobalStyle;
  MainBox: typeof MainBox;
  Container: typeof Container;
}

const Gs: IGs = {
  GlobalStyle: GlobalStyle,
  MainBox,
  Container
}

export default Gs
