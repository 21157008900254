import {
  BaseToken,
  CreatePoolType,
  LiquidityParams,
  LiquidityPosition,
} from "types";

export enum ActionTypes {
  SET = "LIQUIDITY/SET",
  RESET = "LIQUIDITY/RESET",
  SET_USER_INFO = "LIQUIDITY/USER_INFO",
  SET_LP_ADDRESS = "LIQUIDITY/SET_LP_ADDRESS",
  SET_ACTIVED_LP_ADDRESS = "LIQUIDITY/SET_ACTIVED_LP_ADDRESS",
  SET_CREATE_POOL_TYPE_WITH = "LIQUIDITY/SET_CREATE_POOL_TYPE_WITH",
  SET_TOKEN_A = "LIQUIDITY/SET_TOKEN_A",
  SET_TOKEN_B = "LIQUIDITY/SET_TOKEN_B",
  SET_TOKEN_PRICES = "LIQUIDITY/SET_TOKEN_PRICES",
  SET_IS_ADD_SECURE_FLOOR = "LIQUIDITY/SET_IS_ADD_SECURE_FLOOR",
  SET_POOL_INFO = "LIQUIDITY/SET_POOL_INFO",
}

export interface ActionSet {
  type: ActionTypes.SET;
  payload: LiquidityParams;
}

export interface ActionSetIsAddSecureFloor {
  type: ActionTypes.SET_IS_ADD_SECURE_FLOOR;
  payload: boolean;
}

export interface ActionReset {
  type: ActionTypes.RESET;
}

export interface ActionSetUserInfo {
  type: ActionTypes.SET_USER_INFO;
  payload: {
    usdSwapped: number;
    liquidityPositions: LiquidityPosition[];
  };
}

export interface ActionSetLPAddress {
  type: ActionTypes.SET_LP_ADDRESS;
  payload: {
    isLoaded: boolean;
    address: string;
  };
}

export interface ActionSetTokenA {
  type: ActionTypes.SET_TOKEN_A;
  payload: {
    name: string;
    symbol: string;
    address: string;
    decimals: string;
  };
}

export interface ActionSetTokenB {
  type: ActionTypes.SET_TOKEN_B;
  payload: {
    name: string;
    symbol: string;
    address: string;
    decimals: string;
  };
}

export interface ActionSetTokenPrices {
  type: ActionTypes.SET_TOKEN_PRICES;
  payload: [string | number, string | number];
}

export interface ActionSetCreatePoolType {
  type: ActionTypes.SET_CREATE_POOL_TYPE_WITH;
  payload: CreatePoolType;
}

export interface ActionSetActivedLPAddress {
  type: ActionTypes.SET_ACTIVED_LP_ADDRESS;
  payload: string;
}

export interface ActionSetPoolInfo {
  type: ActionTypes.SET_POOL_INFO;
  payload: {
    token0: BaseToken;
    token1: BaseToken;
    reserve0: string; // number in string;
    reserve1: string; // number in string;
  };
}

export type ActionLiquidity =
  | ActionSet
  | ActionReset
  | ActionSetUserInfo
  | ActionSetTokenA
  | ActionSetTokenB
  | ActionSetIsAddSecureFloor
  | ActionSetTokenPrices
  | ActionSetLPAddress
  | ActionSetCreatePoolType
  | ActionSetActivedLPAddress
  | ActionSetPoolInfo;
