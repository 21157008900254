import styled from 'styled-components'
import { AppButton } from 'Component/core/elements/Buttons'
import { Accordion } from 'react-accessible-accordion'
import { FlexDiv } from 'Component/core/elements/others/styled'
import { Divider } from 'Component/core/elements/Divider'

export const SVote = styled.button`
  position: absolute;
  top: -2px;
  right: -2px;
  padding: 7px 10px;
  min-width: 50px;
  border-start-end-radius: 10px;
  border-bottom-left-radius: 10px;
  color: ${({ theme }) => theme.colorGrey10};
  font-size: ${({ theme }) => theme.fontSize['sm']};
  background-color: ${({ theme }) => theme.colorGrey11};
  &:hover {
    color: white;
    background-color: ${(props) => props.theme.colorPrimary};
  }
`

export const TokenImage = styled.img`
  width: 55px;
  min-width: 55px;
  margin-top: auto;
  margin-bottom: auto;
  max-height: 40px;
  object-fit: cover;
`

export const InvestButton = styled(AppButton)`
  border-color: ${({ theme }) => theme.bodybg09};
  &.primary {
    box-shadow: 0 0 20px -5px ${({ theme }) => theme.colorPrimary};
  }
  border-radius: 0 10px 10px 0;
  padding-left: 20px;
  padding-right: 20px;
`

export const MaxButton = styled.button`
  text-align: left;
  width: 55px;
`

export const SInputWrap = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bodybg09};
  box-shadow: var(--dx-ff-box-shadow-1) ${({ theme }) => theme.bodybg09};

  &.invalid {
    box-shadow: var(--dx-ff-box-shadow-2) red;
  }

  & > input {
    width: 100%;
  }
  ${InvestButton} {
    border-radius: 0 10px 10px 0;
    max-width: 100px;
  }
`

export const ProjectName = styled.a`
  font-size: ${({ theme }) => theme.fontSize.md};
`

export const ProjectSymbol = styled.h6`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.md};
`

export const TwitterBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0 0 10px 10px;
  color: black;
  background-color: ${({ theme }) => theme.twitterButtonColor};
  height: 70px;

  &.creator {
    justify-content: center;
    cursor: pointer;
    padding: 20px;
    &:hover {
      box-shadow: ${({theme}) => theme.twitterButtonBoxShadow};
      background-color: ${({ theme }) => theme.twitterButtonHoverColor};
      color: ${({theme}) => theme.twitterButtonTextColor};
      .twitter-content {
        color: white;
      }
    }
  }
  .viewer-buttons {
    height: 100%;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    &:nth-child(1) {
      border-bottom-left-radius: 10px;
    }
    &:nth-child(3) {
      border-bottom-right-radius: 10px;
    }
  
    &:hover {
      background-color: ${({ theme }) => theme.twitterButtonHoverColor};
    }
  }
  & .twitter-icon {
    min-width: 30px;
    margin-right: 20px;
    font-size: 30px;
    filter: drop-shadow(0px 1px 0px ${({ theme }) => theme.grayBorder2});
  }
  & .twitter-content {
    margin: 0;
    font-family: var(--dx-ff-secondary);
    font-size: ${({ theme }) => theme.fontSize.md};
    font-weight: 800;
    text-shadow: 0 1px 0px ${({ theme }) => theme.grayBorder2};
  }
`

export const LaunchPoolAccordionBondDetails = styled.div``
export const LaunchPoolAccordion = styled(Accordion)`
  & div[data-accordion-component='AccordionItemHeading'],
  ${LaunchPoolAccordionBondDetails} {
    padding-left: 25px;
    padding-right: 25px;
  }
  &:has(div[data-accordion-component='AccordionItemPanel'][hidden]) {
    padding-bottom: 25px;
  }
`
type BondDetailBoxProps = {
  minHeight?: number
}
export const BondDetailBox = styled.div<BondDetailBoxProps>`
  min-height: ${({ minHeight }) => minHeight || 250}px;
`

export const SecureFloorDetailBox = styled(FlexDiv)`
  .SecureFloorDetailBox-FloorSection {
    width: 60%;
  }
  .SecureFloorDetailBox-DiscountSection{
    width: calc(40% - 1px);
  }
`
type ProjectDetailBoxProps = {
  detailWidth?: number;
}
export const ProjectDetailBox = styled.div<ProjectDetailBoxProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  color: white;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  .ProjectDetailBox-Info {
    display: flex;
    margin-top: 2px;
    padding-top: 5px;
    width: ${({ detailWidth }) => detailWidth || 60}%;
    &-Details {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .ProjectDetailBox-Detail {
    width: calc(${({detailWidth}) => 100 - (detailWidth || 60)}% - 1px);
    padding-left: 15px;
  }
  ${Divider} {
    height: 55px !important;
  }
`
