import { BsQuestionCircleFill } from "react-icons/bs";
import {
  DefaultTokenObject,
  NetworkUrls,
  ProposalItem,
  SmartContractConfig,
  SupportedNetworks,
  VoteRule,
  VoteVars,
} from "types";
import tooltip from "./tooltip-texts";
import { secondsToLabel } from "helper/dynamicHelpers";

const UniswapV2SbugraphETH =
  "https://gateway.thegraph.com/api/016994ffe6e3a893f82589307a90ce07/subgraphs/id/EYCKATKGBKLWvSfwvBjzfCBmGwYNdVkduYXVivCsLRFu";

// "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2";
const SushiswapSubgraphBSC =
  "https://gateway.thegraph.com/api/016994ffe6e3a893f82589307a90ce07/subgraphs/id/7QyoZFgo1bYvTD7bmdBxsjFLjmeDLgBT3nC3H8y6yuKZ";
// "https://api.thegraph.com/subgraphs/name/sushiswap/bsc-exchange";

export const BSwapfactoryContractAddress: SmartContractConfig = {
  [SupportedNetworks.mainnet]: "0x5e4d937e6fd46d71da7e9db5c5bec13b875bf4e4",
  [SupportedNetworks.bsc]: "0x044c1f2034eae5fee0c27c32c9fd1b9f31157901",
  [SupportedNetworks.bsctestnetwork]:
    "0x5a38d2551a6fe54e118a4da94b0b33bf4b84538a",
  [SupportedNetworks.sepolia]: "0x581211715309d49efb55d35afedca6fdbf091a21",
};

export const BSwaprouter2ContractAddress: SmartContractConfig = {
  [SupportedNetworks.mainnet]: "0x538177652be5c80b420e7e27afeec9f5dae76c08",
  [SupportedNetworks.bsc]: "0x2249019cd814ed66c1034a8e6bce44cd1d464ace",
  [SupportedNetworks.bsctestnetwork]:
    "0x1d62f1f7df41c5331ae887f4f1809578133afc91",
  [SupportedNetworks.sepolia]: "0x41158f05ea48bc96af4f85179025670ac67c0983",
};

// subgraph urls
export const DEREX_SUBGRAPH: NetworkUrls = {
  [SupportedNetworks.mainnet]:
    "https://gateway-arbitrum.network.thegraph.com/api/9abf342517a3087452180fa2de9a5daf/subgraphs/id/HRNbEoHnaAbw36nFWbd52kFXkcjXMSTeBrSPaL41P4oV",
  [SupportedNetworks.bsc]:
    "https://gateway-arbitrum.network.thegraph.com/api/016994ffe6e3a893f82589307a90ce07/subgraphs/id/GpuKdNxKFzkyvPE8BbH847eK9qTJA2t5m6RwetYq98Tb",
  // 'https://api.thegraph.com/subgraphs/name/fhrrydeveloper/derex',
  [SupportedNetworks.bsctestnetwork]:
    "https://gateway-arbitrum.network.thegraph.com/api/016994ffe6e3a893f82589307a90ce07/subgraphs/id/G9MCammpXK8kPwrbF8c2diNFrNydtLdv7HzF43v1thDX",
  // 'https://api.thegraph.com/subgraphs/name/fhrrydeveloper/ibo-tbsc',
  [SupportedNetworks.sepolia]:
    "https://gateway.thegraph.com/api/9abf342517a3087452180fa2de9a5daf/subgraphs/id/CKRjbMknx6xhyryxnNdS4VxSoZG6yHEg6sTdeemmHgGg",
};

export const AlternativeTokenDataSubgraphs: NetworkUrls<string[]> = {
  [SupportedNetworks.mainnet]: [
    DEREX_SUBGRAPH[SupportedNetworks.mainnet],
    UniswapV2SbugraphETH,
  ],
  [SupportedNetworks.bsc]: [
    DEREX_SUBGRAPH[SupportedNetworks.bsc],
    SushiswapSubgraphBSC,
  ],
  [SupportedNetworks.bsctestnetwork]: [
    DEREX_SUBGRAPH[SupportedNetworks.bsctestnetwork],
    SushiswapSubgraphBSC,
  ],
  [SupportedNetworks.sepolia]: [
    DEREX_SUBGRAPH[SupportedNetworks.sepolia],
    UniswapV2SbugraphETH,
  ],
};

export const defaultToken: DefaultTokenObject = {
  address: null,
  image: "",
  name: "Select Token",
  symbol: "",
  value: "", // token address
};

export const Rules: Array<VoteRule> = [
  // rule id by index
  { ABI: "setVotingDuration(uint256)", types: ["uint256"] },
  { ABI: "setMinimalLevel(uint256)", types: ["uint256"] },
  { ABI: "setVars(uint256,uint32)", types: ["uint256", "uint256"] },
  { ABI: "switchToPublic()", types: [] },
];

// set vars position like smart contract
export const Vars: VoteVars = {
  timeFrame: {
    // token1 & token0 limitation per time period in seconds
    index: 0,
    decimals: 0,
  },
  maxDump0: {
    // token0 limitation per time period
    index: 1,
    decimals: 2,
  },
  maxDump1: {
    // token1 limitation per time period
    index: 2,
    decimals: 2,
  },
  maxTxDump0: {
    // token0 limitation per order in percent but use 2 decimal in contract
    index: 3,
    decimals: 2,
  },
  maxTxDump1: {
    // token1 limitation per order in percent but use 2 decimal in contract
    index: 4,
    decimals: 2,
  },
  coefficient: {
    // coefficient in percent
    index: 5,
    decimals: 2,
  },
  minimalFee: {
    // minimal fee in percent
    index: 6,
    decimals: 2,
  },
  periodMA: {
    // WMA Duration in seconds
    index: 7,
    decimals: 0,
  },
};

const Proposals: ProposalItem[] = [
  {
    index: 1,
    title: "Pool Type",
    ruleId: 3,
    order: 2,
    about: (
      <p>
        About pool type
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={tooltip("About pool type")}
        />
      </p>
    ),
    getVoteDescrition: () => "Change Pool type to public",
  },
  {
    index: 2,
    title: "Maximum sell allowance of TOKEN0 price per transaction",
    ruleId: 2,
    poolVar: Vars.maxTxDump0,
    order: 1,
    about: (
      <p>
        About max sell allowance
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={"Coming Soon"}
        />
      </p>
    ),
    getVoteDescrition: (token0: string, to: string) =>
      `Change Max Sell Allowance of ${token0} price per transaction to ${to}%`,
  },
  {
    index: 3,
    title: "Maximum sell allowance of TOKEN1 price per transaction",
    ruleId: 2,
    poolVar: Vars.maxTxDump1,
    order: 3,
    about: (
      <p>
        About max sell allowance
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={"Coming Soon"}
        />
      </p>
    ),
    getVoteDescrition: (token1: string, to: string) =>
      `Change Max Sell Allowance of ${token1} price per transaction to ${to}%`,
  },
  {
    index: 4,
    title: "Maximum sell allowance of TOKEN0 price per order",
    ruleId: 2,
    poolVar: Vars.maxDump0,
    about: (
      <p>
        About max sell allowance
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={"Coming Soon"}
        />
      </p>
    ),
    getVoteDescrition: (token0: string, to: string) =>
      `Maximum sell allowance of ${token0} price per order to ${to}%`,
  },
  {
    index: 5,
    title: "Maximum sell allowance of TOKEN1 price per order",
    ruleId: 2,
    poolVar: Vars.maxDump1,
    about: (
      <p>
        About max sell allowance
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={"Coming Soon"}
        />
      </p>
    ),
    getVoteDescrition: (token1: string, to: string) =>
      `Maximum sell allowance of ${token1} price per order to ${to}%`,
  },
  {
    index: 6,
    title: "Emergency complete halt",
    className: "text-danger",
    ruleId: 2,
    getVoteDescrition: (token: string) => `Change 0% slippage for ${token}`,
  },
  {
    index: 7,
    title: "WMA measured",
    ruleId: 2,
    poolVar: Vars.periodMA,
    about: (
      <p>
        About WMA
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={tooltip("About WMA")}
        />
      </p>
    ),
    getVoteDescrition: (dateInSec: string | number) => {
      const dateLabel = secondsToLabel(dateInSec);
      return `Change WMA measured to ${dateLabel}`;
    },
  },
  {
    index: 8,
    title: "Minimum baseline fees",
    ruleId: 2,
    poolVar: Vars.minimalFee,
    about: (
      <p>
        About Minimum baseline fees
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={tooltip("About Minimum baseline fees")}
        />
      </p>
    ),
    getVoteDescrition: (fee: string | number) =>
      `Change Minimum baseline fees to ${fee}%`,
  },
  {
    index: 9,
    title: "Coefficient",
    ruleId: 2,
    poolVar: Vars.coefficient,
    about: (
      <p>
        About Coefficient
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={tooltip("About Coefficient")}
        />
      </p>
    ),
    getVoteDescrition: (coefficient: string | number) =>
      `Change Coefficient to ${coefficient}%`,
  },
  {
    index: 10,
    title: "Minimum voting power to create a proposal",
    ruleId: 1,
    about: (
      <p>
        About voting power
        <BsQuestionCircleFill
          className="fas helpIco"
          data-type="light"
          data-html="true"
          data-class="data-tooltip"
          data-tip={"Coming Soon"}
        />
      </p>
    ),
    getVoteDescrition: (votingPower: string | number) =>
      `Change Minimum voting power to create a proposal to ${votingPower}%`,
  },
  // {
  //   index: 11,
  //   title: 'Voting duration',
  //   ruleId: 2,
  //   poolVar: Vars.timeFrame,
  //   about: (
  //     <p>
  //       About voting duration
  //       <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
  //     </p>
  //   ),
  //   getVoteDescrition: (votingDuration: string | number) => `Change voting duration to vote to a proposal to ${votingDuration}`,
  // },
];

// @ts-ignore
export const getProposals = (): ProposalItem[] =>
  Proposals.sort((a, b) => (a.order && b.order && a.order > b.order ? -1 : 0));
export const getProposal = (
  ruleId: string | number,
  ruleIndex: string | number | undefined = undefined
): ProposalItem | undefined => {
  const proposals = getProposals();
  if (ruleIndex) {
    return proposals.find((p) => {
      return (
        p.ruleId === +ruleId && p.poolVar && p.poolVar.index === +ruleIndex
      );
    });
  }
  return proposals.find((p) => {
    return p.ruleId === +ruleId && typeof p.poolVar === "undefined";
  });
};
