import { LOCAL_STORAGE_ITEM_NAMES } from "app-constants";
import { isEqAddr } from "helper";
import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from "helper/localStorage";
import {
  AppCustomToken,
  AppTokenListData,
  TokenImages,
  TokenListItem,
} from "types";
import { ActionTokenList, ActionTypes } from "./types";

type TokenListReducerState = {
  items: Array<TokenListItem>; // items // urls
  itemData: Array<AppTokenListData>; // <ItemIndex>: [tokens] // response
  tokenImages: TokenImages;
  customTokens: AppCustomToken[];
};

const initialState: TokenListReducerState = {
  items: [], // items // urls
  itemData: [], // <ItemIndex>: [tokens] // response
  tokenImages: {}, // [adress in lowercased string]: token url in string
  customTokens:
    loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.APP_CUS_TOKENS) || [],
};

function filterTokenImages(_itemData: Array<AppTokenListData>) {
  const tokenImages: TokenImages = {};
  if (Array.isArray(_itemData)) {
    _itemData.forEach((data) => {
      if (Array.isArray(data.tokens)) {
        data.tokens.forEach((token) => {
          tokenImages[`${token.address.toLowerCase()}`] = token.image;
        });
      }
    });
  }
  return tokenImages;
}

export default function tokenListReducer(
  state = initialState,
  action: ActionTokenList
): TokenListReducerState {
  let tokens,
    origin,
    newToken,
    items,
    newItem,
    index: number,
    itemData: Array<AppTokenListData>,
    tokenImages = {},
    oldCustomTokens: AppCustomToken[];

  switch (action.type) {
    // itemData start
    case ActionTypes.set:
      tokenImages = filterTokenImages(action.payload);
      return { ...state, itemData: action.payload, tokenImages: tokenImages };
    case ActionTypes.add:
      itemData = [...state.itemData, action.payload];
      tokenImages = filterTokenImages(itemData);
      return { ...state, itemData: itemData, tokenImages };
    case ActionTypes.init:
      return { ...state, itemData: [], tokenImages: {} };
    case ActionTypes.setItem:
      tokens = state.itemData.slice();
      origin = tokens[action.payload.index];
      newToken = { ...origin, ...action.payload.tokens };
      tokens[action.payload.index] = newToken;
      tokenImages = filterTokenImages(tokens);
      return { ...state, itemData: tokens, tokenImages };
    case ActionTypes.setItems:
      return { ...state, items: action.payload, itemData: [], tokenImages: {} };
    case ActionTypes.remove:
      items = state.items;
      itemData = state.itemData;
      items.splice(action.payload.index, 1);
      itemData.splice(action.payload.index, 1);
      tokenImages = filterTokenImages(itemData);
      return { ...state, items, itemData, tokenImages };
    // itemData end
    case ActionTypes.updateItem:
      items = state.items.slice();
      origin = items[action.payload.index];
      newItem = { ...origin, ...action.payload.item };
      items[action.payload.index] = newItem;
      return { ...state, items: items };

    case ActionTypes.ADD_CUSTOM_TOKEN:
      oldCustomTokens = state.customTokens.slice();
      oldCustomTokens.push(action.payload);
      saveStateToLocalStorage(
        LOCAL_STORAGE_ITEM_NAMES.APP_CUS_TOKENS,
        oldCustomTokens
      );
      return { ...state, customTokens: oldCustomTokens };

    case ActionTypes.REMOVE_CUSTOM_TOKEN:
      oldCustomTokens = state.customTokens.slice();
      index = oldCustomTokens.findIndex(
        (t) =>
          isEqAddr(t.address, action.payload.address) &&
          t.chain === action.payload.chainId
      );
      oldCustomTokens.splice(index, 1);
      saveStateToLocalStorage(
        LOCAL_STORAGE_ITEM_NAMES.APP_CUS_TOKENS,
        oldCustomTokens
      );
      return { ...state, customTokens: oldCustomTokens };

    case ActionTypes.CLEAR_ALL_CUSTOM_TOKEN:
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.APP_CUS_TOKENS, []);
      return { ...state, customTokens: [] };

    default:
      return state;
  }
}
