import React from 'react'
import styled from 'styled-components'

const SText = styled.h1`
`

function ApiDoc() {
    return (
        <div className='d-flex align-items-center justify-content-center h-80vh'>
            <SText>DEREX.Exchange API Documentation</SText>
        </div>
    )
}

export default ApiDoc
