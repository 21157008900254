import {
  AppCommunity,
  AppThemeConfigs,
  TGlobalDexes,
  ThemeConfigNameType,
} from "types";

export const appThemeConfigs: AppThemeConfigs = {
  default: {
    themeName: "dark",
    footerLogoUri: "/images/DEREX-footer.png",
  },
  lpInsure: {
    themeName: "lpInsure",
    pathReg: /\/lp/gi,
    footerLogoUri: "/images/lp-insure/LP-footer.png",
  },
};

export const appThemeConfigNames = Object.keys(
  appThemeConfigs
) as ThemeConfigNameType[];

export const AppCommunities: AppCommunity[] = [
  {
    link: "https://smart.exchange/",
    image: "/images/lp-dropdown-logos/smart-exchange-logo.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  {
    link: "https://nft.protection/",
    image: "/images/lp-dropdown-logos/nft-protection-logo.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  {
    link: "https://derex.exchange/",
    image: "/images/lp-dropdown-logos/derex-exchange-logo.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  {
    link: "https://ibo.derex.exchange/",
    image: "/images/lp-dropdown-logos/IBOpad-logo.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  {
    link: "https://pdo.finance/",
    image: "/images/lp-dropdown-logos/pdo-finance-logo.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  {
    link: "https://lp.insure/",
    image: "/images/lp-dropdown-logos/lp-insure-logo.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  {
    link: "https://www.dumpershield.exchange/",
    image: "/images/lp-dropdown-logos/dumper-shield-logo.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  {
    link: "https://freez.finance/",
    image: "/images/lp-dropdown-logos/freez-finance.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  {
    link: "https://degenswap.io/",
    image: "/images/lp-dropdown-logos/degen-logo.png",
    style: {
      height: 67,
      width: "auto",
    },
  },
  // {
  //   link: "https://Uniguard.exchange",
  //   image: "/images/lp-dropdown-logos/uniguard-logo.png",
  //   style: {
  //     height: 67,
  //     width: "auto",
  //   },
  // },
  // {
  //   link: "https://cbdc.science/",
  //   image: "/images/lp-dropdown-logos/cbdc-science.png",
  //   style: {
  //     height: 67,
  //     width: "auto",
  //   },
  // },
  // {
  //   link: '#',
  //   image: '/images/lp-dropdown-logos/multiverse-logo.png',
  //   style: {
  //     height: 67,
  //     width: 'auto',
  //   },
  // },
  // {
  //   link: '#',
  //   image: '/images/lp-dropdown-logos/vtuber-exchange-logo.png',
  //   style: {
  //     height: 67,
  //     width: 'auto',
  //   },
  // },
  // {
  //   link: '#',
  //   image: '/images/lp-dropdown-logos/vsinger-exchange-logo.png',
  //   style: {
  //     height: 67,
  //     width: 'auto',
  //   },
  // },
  // {
  //   link: '#',
  //   image: '/images/lp-dropdown-logos/oxn-logo.png',
  //   style: {
  //     height: 67,
  //     width: 'auto',
  //   },
  // },
  // {
  //   link: '#',
  //   image: '/images/lp-dropdown-logos/detf-investments-logo.png',
  //   style: {
  //     height: 67,
  //     width: 'auto',
  //   },
  // },
  // {
  //   link: '#',
  //   image: '/images/lp-dropdown-logos/updown-logo.png',
  //   style: {
  //     height: 67,
  //     width: 'auto',
  //   },
  // },
  // {
  //   link: '#',
  //   image: '/images/lp-dropdown-logos/w3b-finance-logo.png',
  //   style: {
  //     height: 67,
  //     width: 'auto',
  //   },
  // },
  // {
  //   link: '#',
  //   image: '/images/lp-dropdown-logos/social-currency-logo.png',
  //   style: {
  //     height: 67,
  //     width: 'auto',
  //   },
  // },
];

export const GlobalDexes: TGlobalDexes = {
  derex: {
    chainId: 97,
    icon: "/images/DEREX-footer.svg",
    name: "Derex",
    router: "0x1d62f1f7df41c5331ae887f4f1809578133afc91",
    site: "https://app.derex.exchange",
  },
};
