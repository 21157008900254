import { ToolTipText } from "types";

const ToolTips: ToolTipText = {
  "Coming Soon": "Coming Soon",
  "Type of pool":
    "To avoid rug pulling by bad actors, DEREX exchange allows projects to vote on locking their pool as private or open the pool to public. Once a pool is private, only existing LPs are able to continue providing liquidity to the pool.",
  "DMC limitation per order":
    "The per order Circuit Breaker Limits quick finger or bot trades from having an advantage over other users on the selected token in the LP pairing.",
  "BNB limitation per order":
    "The per order Circuit Breaker limits quick finger or bot trades from having an advantage over other users on the selected token in the LP pairing.",
  "DMC limitation per time period":
    "The per time period Circuit Breaker limits the total slippage that can be created in the pool during a specific time unit. The default per time Circuit Breaker setting for new pools with native tokens is 10% per day",
  "BNB limitation per time period":
    "The per time period Circuit Breaker Limits the total slippage that can be created in the pool during a specific time unit. The default per time Circuit Breaker setting ofr new pools with native tokens is 10% per day.",
  "WMA duration":
    "The WMA Circuit Breaker triggers when volatility in the pool reaches above or below a predicted percentage over the moving average price in the pool. LPs can set limitations for one pair  in the pool or both. Once the WMA is back in range, smart contracts automatically trigger which resumes.",
  "Minimum basseline fees":
    "The higher demand for a pair carries higher fees while lower demand for a pair reduces to the minimum as low as 0.05% fee",
  Coefficient:
    "When the smart contract calculates the fees based on the WMA it then multiplies the result by the coefficient percent factor that measures the final fee impact. As a default the coefficient is set at 100%.",
  "Voting duration":
    "Amount of voting time before a proposal closes and executes",
  "Max slippage allowed":
    "Setting a high slippage can help transactions succeed, but will hurt your final price and be expose to front0running attacks",
  "Transaction deadline":
    "Time to remain pending before the <br/> transaction fails.",
  "Auto Router":
    "Allow the system to use multiple pools to achieve the best slippage rate",
  "This Pool is Private":
    "Private pools only allow existing LPs to continue providing liquidity to the pool.",
  "Dumper Shield protection":
    "Dumper Shield is the next generation of smart vesting solutions, offering a revolutionary approach designed to protect against token price dumping. By leveraging a quantum finance fusion strategy, it seamlessly merges vesting tokens with the existing circulation supply based on REAL-TIME market liquidity and demand, rather than simply following a predetermined schedule like traditional vesting solutions. <br/><br/> Read more about the dumper shield<br /> <a href='https://www.dumpershield.exchange' style='color:green;'>www.dumpershield.exchange<a/>",
  "Dynamic Fees":
    "Fluctuating fees start from 0.1% and increases in realtime based on market demand, calculated by the Weighted Moving average (WMA) of the pool",
  "Tokens supply": "Total tokens supply for bond creation",
  FDV: "Fully diluted value of the bond offering",
  Vesting:
    "Amount of time required to stake principal funds before withdrawing",
  "Bond face value": "Final total price after full vesting with discount",
  Discount: "Discount percentage provided through holding the bold to term",
  "Funds distributions": "Shows where the principal is automatically sent.",
  "Secure Floor Protection":
    "The Secure Floor smart contract automatically triggers the complete halt circuit breaker at the set floor which reduces the slippage to 0%",
  BondDiscount:
    "The bonds pool allows projects to incentivize with a 50% discount encouraging LPs to hold for longer periods to increase rewards. By using the bond pool, investors do not need to buy upfront tokens, then pair the tokens with new funds and then push them back to the pool, followed by staking the LP in a farming contract. The LP via bond pool allows all of this to take place in one click, while protecting the project from speculating on LP actions.",
  "Investment Limits": "Limited individual investor contributions",
  "Set up a buy/sell tax":
    "A smart contract owner can set a buy and or a sell tax fee and choose the distribution destination address.",
  BOND_PAGE_OFFER_TOOLTIP:
    "Combing bonds and secure floor protection, projects can offer LPs 100% impermanent loss protection even after a pool is created.",
  PREPAYMENT_PENALTY:
    "Withdraw penalty for claiming before the vesting period ends.",
  // bond terms
  Refund_To_Project:
    "Wallet that launches the IBO is able to claim leftover tokens",
  Dynamic_Penalty:
    "Penalty percentage will decrease daily towards the end of the vesting period",

  // fundraising terms
  Select_Dumper_Shield_Period:
    "The duration of the period will determine the length of time during which the tokens will remain vesting and locked behind Dumper Shield. After this date, the tokens will become vested and can be withdrawn to the wallet. The duration of the Dumper Shield period can be increase or decrease in the duration through voting by vesting token holders.",
  Select_Existing_Dex:
    "The duration of the period will determine the length of time during which the tokens will remain vesting and locked behind Dumper Shield. After this date, the tokens will become vested and can be withdrawn to the wallet. The duration of the Dumper Shield period can be increase or decrease in the duration through voting by vesting token holders.",
  Invest_Limit_Per_Wallet: "Limits total investment per wallet",
  // deploy bond pool
  Bond_Pool_Burn: "Sends tokens to a zero address",
  "Refund To Project":
    "Wallet that launches the IBO is able to claim leftover tokens",
  Bond_Pool_Supply_Amount:
    "The supply amount includes discounts if bonds are fully realized",
  Bond_Pool_Fundraise_Target:
    "Total amount of funds to raise to the liquidity pool",
  Bond_Pool_APY_FOR_USERS:
    "The APY offered to users will determine how the allocation between principal and APY rewards is split. For example, if a project deposits 100 tokens to the PDO with a 100% APY, 50 tokens will be allocated for the buyer to purchase, while the remaining 50 tokens will be used as APY reward.",
  Bond_Pool_Choose_Shield_Period: "The Length of the dumper shield period",
  Bond_Pool_UP_TO_100BNB: "The fee is 0% for up to $10,000 raised",
  Bond_Pool_5P_TOKEN_FEES:
    "10% of the fees will be placed in an escrow smart contract and will be secured behind the Dumper Shield.  Learn more about Dumper Shield",
  Bond_Pool_5P_TOKEN_FEES_ENROLL:
    "DEREX charges no fees from raised funds for LP investors, but charges fees using project tokens based on the actual fundraising amount. The fee is 0% for up to $10,000 raised and 10% for amounts over $10,000. ",
  Bond_Token_Potion:
    "The percentage of tokens that are claimable each set time period",
  Bond_Token_Every: "Frequency of claimable tokens dispersed",
  Bond_Approve_Terms:
    "Secure Floor prevents LP investors from being affected by dumping risks. When launching pools on DEREX, projects must set the token ratio, creating a baseline ratio that becomes the Secure Floor. If the project token's value increases, LP investors profit, but if it drops and reaches the Secure Floor, the system triggers a circuit breaker, halting trading and protecting the baseline price from further drops",
  "About Coefficient":
    "When the smart contract calculates the fees based on the WMA it then multiplies the result by the coefficient percent factor that measures the final fee impact. As a default the coefficient is set at 100%",
  "About Minimum baseline fees":
    "The higher demand for a pair carries higher fees while lower demand for a pair reduces to the minimum as low as 0.05% fee",
  "About WMA":
    "The WMA Circuit Breaker triggers when volatility in the pool reaches above or below a predicted percentage over the moving average price in the pool. LPs can set limitations for one pair in the pool or both. Once the WMA is back in range, smart contracts automatically trigger which resumes trading.",
  "About pool type":
    "To avoid rug pulling by bad actors, DEREX exchange allows projects to vote on locking their pool as private or open the pool to the public. Once a pool is private, only existing LPs are able to continue providing liquidity to the pool.",
  "Principal Cliff":
    "A cliff is a period of time that must pass before tokens are vested. During this time, no tokens will be vested, and if the vesting period is not completed, no tokens will be released. The purpose of a cliff is to ensure that recipients remain committed to the project for a certain amount of time before receiving tokens.",
  Cliff:
    "Utilize the investment cliff to distribute tokens in equal tranches after a set lock time",
  "Supply Amount":
    "The supply amount includes discounts if bonds are fully realized",
  "Target Raise": "Total amount of funds to raise to the liquidity pool",
  "Dumper Shield for users":
    "The Dumper Shield is a decentralized OTC exchange for vesting tokens. It is used to prevent token holders from selling their discounted tokens on the market at a reduced value, which can harm other holders. The Dumper Shield solution allows holders to sell their tokens indirectly to the secondary market or as OTC below market value without affecting the token's value in exchanges or liquidity pools, and new buyers are added to the Dumper Shield.",
  "Staking period for users":
    "Reward received by users for remaining during the vesting period",
  "Fork this pool":
    "DEREX uses pool forking to solve governance issues in traditional DeFi pools. These pools ofren rely on DAO voting structures, which can be unfair to minority members. With pool forking, DEREX allows a minority to create a new pool with their own rules if they disagree with the majority's consensus. The platform prioritizes displaying the pool with higher liquidity to the public while still providing access to the smaller pool through a direct interface.",
};

function tooltip(name: keyof ToolTipText | string) {
  return ToolTips[name] || name;
}

export default tooltip;
