import TokenIco01 from "Assets/images/icon-3.png";
import TokenIco02 from "Assets/images/selectTkn-02.png";
import degen_token from "Assets/images/Degen_token.png";
import TokenIco12 from "Assets/images/selectTkn-12.png";
import TokenIco10 from "Assets/images/selectTkn-10.png";
import TokenIco11 from "Assets/images/selectTkn-11.png";
import SSIcon06 from "Assets/images/ethRedioICON.png";
import bnb from "Assets/images/bnb-icon.png";
import {
  DEGEN_TOKEN_ADDRESS_BSC,
  DMC_TOKEN_ADDRESS,
  INFRA_PROJECT_ID,
} from "app-constants";
import { tBNB } from "./addresses";
import {
  AppBuyNowTokenConfig,
  AppSupportedChain,
  SupportedNetworks,
} from "types";

export const BuyNowTokens: AppBuyNowTokenConfig = {
  1: [
    {
      symbol: "ETH",
      name: "ETH",
      chain: "Ethereum",
      image: SSIcon06,
      decimals: "18",
      value: "0x0000000000000000000000000000000000000000",
      address: "0x0000000000000000000000000000000000000000",
      balance: 0,
    },
    {
      symbol: "USDT",
      name: "Tether USD",
      chain: "Ethereum",
      image: TokenIco12,
      decimals: "6",
      value: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      balance: 0,
    },
    {
      symbol: "USDC",
      name: "USD COin",
      chain: "Ethereum",
      image: TokenIco10,
      decimals: "6",
      value: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      balance: 0,
    },
    {
      symbol: "DAI",
      name: "Dai",
      chain: "Ethereum",
      image: TokenIco02,
      decimals: "18",
      value: "0x6b175474e89094c44da98b954eedeac495271d0f",
      address: "0x6b175474e89094c44da98b954eedeac495271d0f",
      balance: 0,
    },
    {
      address: DMC_TOKEN_ADDRESS[1],
      value: DMC_TOKEN_ADDRESS[1],
      decimals: "18",
      image: "/images/dmc-token.png",
      chain: "Ethereum",
      name: "DMC",
      symbol: "DMC",
      balance: 0,
    },
  ],
  56: [
    {
      symbol: "BNB",
      name: "BNB",
      chain: "bsc",
      decimals: "18",
      address: "0x0000000000000000000000000000000000000000",
      value: "0x0000000000000000000000000000000000000000",
      image: bnb,
      balance: 0,
    },
    {
      symbol: "DEGEN",
      name: "DEGEN" /** Degen Swap Token */,
      decimals: "18",
      chain: "bsc",
      address: DEGEN_TOKEN_ADDRESS_BSC,
      value: DEGEN_TOKEN_ADDRESS_BSC,
      image: degen_token,
      balance: 0,
    },
    {
      address: DMC_TOKEN_ADDRESS[56],
      value: DMC_TOKEN_ADDRESS[56],
      decimals: "18",
      image: "/images/dmc-token.png",
      chain: "bsc",
      // label: 'DMC',
      name: "DMC",
      symbol: "DMC",
      balance: 0,
    },
  ],
  97: [
    {
      symbol: "tBNB",
      name: "tBNB",
      decimals: "18",
      chain: "bsc",
      address: "0x0000000000000000000000000000000000000000",
      value: "0x0000000000000000000000000000000000000000",
      image: bnb,
      balance: 0,
    },
    {
      symbol: "tDMC",
      name: "DMC",
      chain: "bsc",
      decimals: "18",
      image: "/images/dmc-token.png",
      // label: 'DMC',
      address: DMC_TOKEN_ADDRESS[97],
      value: DMC_TOKEN_ADDRESS[97],
      balance: 0,
    },
  ],
  11155111: [
    {
      symbol: "ETH",
      name: "ETH",
      chain: "Sepolia",
      image: SSIcon06,
      decimals: "18",
      value: "0x0000000000000000000000000000000000000000",
      address: "0x0000000000000000000000000000000000000000",
      balance: 0,
    },
    {
      symbol: "tDMC",
      name: "DMC",
      chain: "bsc",
      decimals: "18",
      image: "/images/dmc-token.png",
      // label: 'DMC',
      address: DMC_TOKEN_ADDRESS[11155111],
      value: DMC_TOKEN_ADDRESS[11155111],
      balance: 0,
    },
  ],
};

export const SupportedChains: AppSupportedChain[] = [
  {
    name: "Ether",
    label: "Ethereum",
    chain: "Ethereum",
    symbol: "ETH",
    decimals: "18",
    chainId: SupportedNetworks.mainnet,
    image: SSIcon06,
    value: "0x0E11862a0Da0C38F15568A8FA2C992Afc1f0b278",
    address: "0x0E11862a0Da0C38F15568A8FA2C992Afc1f0b278",
    rpcUrls: ["https://cloudflare-eth.com"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  {
    name: "BNB",
    label: "BNB Smart Chain",
    chain: "BSC",
    symbol: "BNB",
    chainId: SupportedNetworks.bsc,
    decimals: "18",
    image: TokenIco01,
    value: "0x078F9018a2fF0D7a0FE919Ff6e4d2485a0d78d8c",
    address: "0x078F9018a2fF0D7a0FE919Ff6e4d2485a0d78d8c",
    rpcUrls: ["https://bsc.publicnode.com"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  {
    name: "TBSC",
    label: "Binance Smart Chain Testnet",
    chain: "TBSC",
    decimals: "18",
    symbol: "tBNB",
    chainId: SupportedNetworks.bsctestnetwork,
    image: TokenIco01,
    value: tBNB,
    address: tBNB,
    rpcUrls: ["https://data-seed-prebsc-1-s1.bnbchain.org:8545"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  {
    name: "Sepolia",
    label: "Sepolia Testnet",
    chain: "Sepolia",
    symbol: "ETH",
    decimals: "18",
    chainId: SupportedNetworks.sepolia,
    image: SSIcon06,
    value: "0xfff9976782d46cc05630d1f6ebab18b2324d6b14",
    address: "0xfff9976782d46cc05630d1f6ebab18b2324d6b14",
    rpcUrls: [`https://sepolia.infura.io/v3/${INFRA_PROJECT_ID}`],
    blockExplorerUrls: ["https://sepolia.etherscan.io"],
  },
];
