import { toBN } from "web3-utils";
import { isEqAddr } from "./others";
import { BOND_ALTERNATIVE_TOKENS } from "app-constants";
import { BondData, LPInsureData } from "types";
import BN from "bn.js";
import fromExponential from "from-exponential";
import { decimalAmountToExactAmount } from "./math";
import Decimal from "decimal.js";

export const getAlterNativeToken = (token: string) => {
  const keys = Object.keys(BOND_ALTERNATIVE_TOKENS);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key.toLowerCase() === token.toLowerCase()) {
      return BOND_ALTERNATIVE_TOKENS[key];
    }
  }
  return false;
};

export const getTokenRatio = (
  tokenAddress: string,
  [token0, token1]: any[],
  [reserve0, reserve1]: any[]
) => {
  let pairToken = "";
  if (isEqAddr(tokenAddress, token0.address)) {
    pairToken = token1.address;
  } else if (isEqAddr(tokenAddress, token1.address)) {
    pairToken = token0.address;
  } else {
    console.error("InValid tokens!");
    return 0;
  }
  if (toBN(tokenAddress).gt(toBN(pairToken)))
    [reserve0, reserve1] = [reserve1, reserve0]; // reserve0 of project token, reserve1 of pair token
  return new Decimal(reserve1).div(reserve0).toNumber();
};

export const getRevertedTokenRatio = (
  token: string,
  [token0, token1]: any[],
  [reserve0, reserve1]: any[]
) => {
  if (isEqAddr(token, token0.address)) {
    return new Decimal(reserve0).div(reserve1).toNumber();
  } else if (isEqAddr(token, token1.address)) {
    return new Decimal(reserve1).div(reserve0).toNumber();
  } else {
    console.error("InValid tokens");
    return 0;
  }
};

/**
 *
 * @param {Number} startDate milisecs
 * @param {Number} cliffPeriod secs
 *
 */
export function isAllowedRedeemPrincipal(
  startDate: number,
  cliffPeriod: number
) {
  const now = Date.now();
  cliffPeriod = cliffPeriod * 1e3; // change to milisecs
  const isAllowed = startDate + cliffPeriod < now;
  return isAllowed;
}

export function adjustBondParams(bond: BondData): BondData {
  const vestingParam = bond.paramerters.vestingParam;
  bond.paramerters.vestingParam.prepaymentPenalty =
    vestingParam.prepaymentPenalty / 1e2;

  bond.paramerters.startDate = bond.paramerters.startDate * 1e3;
  bond.paramerters.endDate = bond.paramerters.endDate * 1e3;
  bond.paramerters.feeParam.stakingAPY =
    bond.paramerters.feeParam.stakingAPY / 1e4;

  bond.paramerters.minInvestment =
    bond.paramerters.minInvestment /
    Math.pow(10, +bond.paramerters.pairToken.decimals);
  bond.paramerters.maxInvestment =
    bond.paramerters.maxInvestment /
    Math.pow(10, +bond.paramerters.pairToken.decimals);
  bond.info = null;
  return bond;
}

export function adjustLPInsureParams(insureBond: LPInsureData): LPInsureData {
  insureBond.parameters.startDate = +insureBond.parameters.startDate * 1e3;
  insureBond.parameters.endDate = +insureBond.parameters.endDate * 1e3;

  insureBond.parameters.minInvestment =
    +insureBond.parameters.minInvestment /
    Math.pow(10, +insureBond.pairToken.decimals);
  insureBond.parameters.maxInvestment =
    +insureBond.parameters.maxInvestment /
    Math.pow(10, +insureBond.pairToken.decimals);

  insureBond.parameters.vestingParam.prepaymentPenalty =
    +insureBond.parameters.vestingParam.prepaymentPenalty / 1e2;

  insureBond.info = undefined;
  return insureBond;
}

export function getSupplyAmount(
  supplyAmountBN: string | number,
  tokenDecimals: string | number,
  feeBN: string | number,
  spentTokensBN: string | number
) {
  let supplyAmount = "0";
  if (+feeBN > 0) {
    supplyAmount = fromExponential(+supplyAmountBN - +feeBN);
  } else {
    supplyAmount = fromExponential(+supplyAmountBN);
  }

  const _supplyAmountBN = new BN(supplyAmount, 10);
  const _spentTokensBN = new BN(spentTokensBN, 10);
  // const _feeBN = new BN(fee, 10);
  const availableSupplyAmountBN = _supplyAmountBN.sub(_spentTokensBN); //.sub(_feeBN);
  const availableSupplyAmount = decimalAmountToExactAmount(
    availableSupplyAmountBN.toString(10),
    tokenDecimals
  );
  const supplyAmt = decimalAmountToExactAmount(
    _supplyAmountBN.toString(10),
    tokenDecimals
  );

  return {
    supplyAmount: supplyAmt,
    supplyAmountBN: supplyAmount,
    availableSupplyAmountBN: availableSupplyAmountBN,
    availableSupplyAmount: availableSupplyAmount,
  };
}
