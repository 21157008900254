import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { BOND_PROXY_CONTRACTS, NETWORKS } from "app-constants";
import { SupportedNetworks } from "types";
import BondAbi from 'Assets/Abi/bond-abi.json'
import { PoolParams } from "types/smart-contracts/ibo-proxy-contract";

export const getPoolParams = async (networkId: SupportedNetworks, classId: string, nonceId: string): Promise<PoolParams> => {
    const proxyBondContract = BOND_PROXY_CONTRACTS[networkId]
    const httpProviderUrl = NETWORKS[networkId].HTTP_PROVIDER_URL
    const w3 = new Web3(httpProviderUrl)
    const bondInstance = new w3.eth.Contract(BondAbi as AbiItem[], proxyBondContract)
    const result = await bondInstance.methods.poolParams(classId, nonceId).call()
    return result as PoolParams;
}
