import ReactSlider from 'react-slider'
import type { ReactSliderProps } from 'react-slider'
import styled from 'styled-components'

const DEFAULT_HEIGHT = 10

type StyledSliderCustomProps = {
  height?: number
}

const StyledSlider = styled(ReactSlider)<StyledSliderCustomProps>`
  width: 100%;
  height: ${(props) => props.height || DEFAULT_HEIGHT}px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colorGrey12};

  &.primary {
    .track-0 {
      background: ${({ theme }) => theme.colorPrimary};
      box-shadow: 0 0 5px ${({ theme }) => theme.colorPrimary};
    }
  }
  &.primary-1 {
    .track-0 {
      background: ${({ theme }) => theme.colorPrimary03};
      box-shadow: 0 0 5px ${({ theme }) => theme.colorPrimary03};
    }
  }
`

type StyledThumbProps = {
  height?: number
}
const StyledThumb = styled.div<StyledThumbProps>`
  height: ${(props) => props.height || DEFAULT_HEIGHT}px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #000;
  color: white;
  border-radius: 50%;
  cursor: grab;
`

const Thumb = (props: any, state: any) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  border-radius: 999px;
`

const Track = (props: any, state: any) => <StyledTrack {...props} key={state.index} index={state.index} />

interface IAppSliderProps extends ReactSliderProps<number>, StyledSliderCustomProps {
  showThumb?: boolean
  height?: number
}

// https://zillow.github.io/react-slider/
function AppSlider({ showThumb = false, height, ...props }: IAppSliderProps) {
  // @ts-ignore
  return <StyledSlider renderTrack={Track} renderThumb={showThumb ? Thumb : undefined} height={height} {...props} />
}

export default AppSlider
