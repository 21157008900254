import { useEffect } from "react"
import { LOCAL_STORAGE_ITEM_NAMES } from "app-constants"
import { loadStateFromLocalStorage } from "helper/localStorage"

function IBOTwitterCallback() {
  useEffect(() => {
    const appChainId = loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.CONNECTED_CHAIN)
    const viewId = loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.BOND_VIEW_ID)
  
    const urlSearchParams = new URLSearchParams(window.location.search);
  
    const stateParam = urlSearchParams.get('state');
    const codeParam = urlSearchParams.get('code');
    const extraParams = {
      state: stateParam as string,
      code: codeParam as string,
      // extra: 'bla',
    };
    // Convert the extraParams object to a query string
    const queryString = new URLSearchParams(extraParams).toString();
    // Append the query string to the API URL
    const urlWithParams = `${process.env.REACT_APP_BOND_SERVER as string}/api/ibo-twitter/callback/${appChainId}/${viewId}?${queryString}`;
    // Redirect to the API URL with appended parameters
    window.location.href = urlWithParams;
  }, []);
 
  return (
    <></>
  )
}

export default IBOTwitterCallback
