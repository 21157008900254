import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import Media from "Theme/media-breackpoint";
import {
  InputGroup,
  InputGroupControl,
} from "Component/core/elements/InputGroup";
import { AppButton } from "Component/core/elements/Buttons";
import { BsCheckCircleFill } from "react-icons/bs";

const SApproveSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  ${InputGroup} {
    min-width: 350px;
    max-width: 400px;
  }

  ${AppButton} {
    margin-left: 20px;
    max-width: 200px;
  }
  ${Media.md} {
    ${InputGroup} {
      min-width: 300px;
      max-width: 400px;
    }
  }
  ${Media.xs} {
    ${InputGroup} {
      min-width: 200px;
      max-width: 200px;
    }
    ${AppButton} {
      max-width: 120px;
    }
  }
`;

const SApproveSectionWrap = styled.div`
  text-align: center;
  padding: 30px 30px 10px 30px;
  margin-bottom: 50px;
`;

interface IApproveSectionProps {
  approved?: boolean;
  onClickApproveButton: (...params: any) => void;
  className?: string;
  children: ({ isVaildate }: { isVaildate: boolean }) => void;
}

function ApproveSection({
  children,
  className = "",
  approved = false,
  onClickApproveButton,
}: IApproveSectionProps): JSX.Element {
  const ref = useRef<null | HTMLInputElement>(null);
  const [text, setText] = useState("");
  const isVaildate = useMemo(() => text === "APPROVE", [text]);

  const handleOnClickApprove = (e: any) => {
    onClickApproveButton(e);
  };

  const handleChangeApproveInput = (e: any) => {
    setText(e.target.value);
  };
  const handleOnkeyDown = (e: any) => {
    if (e.key === "Enter" && isVaildate) {
      onClickApproveButton();
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    // @ts-ignore TODO
    <SApproveSectionWrap className={`bg-linear-1 ${className}`}>
      {children({ isVaildate })}
      <SApproveSection>
        {approved ? (
          <BsCheckCircleFill className="text-primary" size={50} />
        ) : (
          <>
            <InputGroup
              className={`border-highlight-shadow-gray-1 ${
                !isVaildate && text && "danger"
              }`}
            >
              <InputGroupControl
                ref={ref}
                value={text}
                onChange={handleChangeApproveInput}
                onKeyDown={handleOnkeyDown}
                className="py-15"
              />
            </InputGroup>
            <AppButton
              className="primary border-highlight-shadow-primary"
              onClick={handleOnClickApprove}
              disabled={!isVaildate}
            >
              Approve
            </AppButton>
          </>
        )}
      </SApproveSection>
      <p className="mt-15 text-title fs-sm">
        Typing approve you agree to the{" "}
        <span className="text-primary">terms and conditions</span>
      </p>
    </SApproveSectionWrap>
  );
}

export default ApproveSection;
