import { AppOtherConfigByNetwork } from "types";
import {
  JNTR_E_ContractAddress,
  JNTR_B_ContractAddress,
  INFRA_PROJECT_ID,
} from "./index";

export type ReimbursementConfig = {
  PrePath: string;
  API_URL: string;
  NETWORK_ID: {
    ETHEREUM: number;
    BINANCE: number;
    1: string;
    56: string;
  };
  currencyAddresses: {
    "JNTR/e": string;
    "JNTR/b": string;
  };
  RPC_PROVIDERS: AppOtherConfigByNetwork;
};

const CONSTANT: ReimbursementConfig = {
  PrePath: "http://elementzero.network/bswap_test",
  API_URL: "https://www.elementzero.network:2096/api/v1/bswapMngment",
  NETWORK_ID: {
    ETHEREUM: 1,
    BINANCE: 56,
    1: "ETHEREUM",
    56: "BINANCE",
  },
  currencyAddresses: {
    "JNTR/e": JNTR_E_ContractAddress,
    "JNTR/b": JNTR_B_ContractAddress,
  },
  RPC_PROVIDERS: {
    1: "wss://mainnet.infura.io/ws/v3/0bc569339d39467c9c1840a2f5c6615f",
    56: "https://bsc-dataseed.binance.org",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    11155111: `https://sepolia.infura.io/v3/${INFRA_PROJECT_ID}`,
  },
};

export default CONSTANT;
