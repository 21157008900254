import styled from 'styled-components'
import Media from 'Theme/media-breackpoint'
import { InputGroup } from 'Component/core/elements/InputGroup'

export const BondPoolBox = styled.div``

export const SPreviewLogo = styled.img`
  width: 70px;
  height: 70px;
  mn-width: 70px;
  min-height: 70px;
`

export const SInfoCardWrap = styled.div`
  display: flex;
  justify-content: center;

  & > .info-card {
    width: 500px;
    margin-left: 20px;
    margin-right: 20px;
  }
`

export const SInvestCodeWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin : 20px 0px;
`

export const SInvestCodeTitle = styled.div`
  display: flex;
  width: 50%;
`

export const SRadioButtonGroup = styled.div`
  display: flex;
  align-items: center;
  
  ${InputGroup} {
    min-width: 250px;
    max-width: 400px;
  }
`

export const WebSiteLinkP = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;

  ${Media.lg2} {
    max-width: 300px;
  }
  ${Media.md} {
    max-width: 200px;
  }
  ${Media.sm} {
    max-width: 100px;
  }
`
