import { appThemeConfigNames, appThemeConfigs } from 'config/app-config'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useThemeConfigName = () => {
  const { pathname } = useLocation()
  return useMemo(() => {
    const themeName = appThemeConfigNames.find((themeName) => {
      const theme = appThemeConfigs[themeName]
      return theme.pathReg && pathname.search(theme.pathReg) > -1
    })

    return themeName || 'default'
  }, [pathname])
}

export default useThemeConfigName
