import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Connectors, BinanceWalletNetworkIds } from "Connectors";
import Web3Utils from "web3-utils";
import useAppSnackbar from "./useAppSnackbar";
import { LOCAL_STORAGE_ITEM_NAMES, SUPPORTED_CHAIN_IDS } from "app-constants";
import { SupportedNetworks, WalletConnectorTypes } from "types";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import { SupportedChains } from "app-constants/conToken";
import Web3 from "web3";
import { useAppChain } from "hooks";
import { useLocation } from "react-router-dom";
import QueryString from "query-string";
import { isMobile } from "react-device-detect";

interface UseWalletReturnType extends Web3ReactContextInterface {
  balance: string | number;
  connectWallet: (_type: WalletConnectorTypes) => Promise<void>;
  switchNetwork: (targetChain: SupportedNetworks) => Promise<void>;
  disconnectWallet: () => void;
}

const useWallet = (): UseWalletReturnType => {
  const {
    active,
    activate,
    chainId,
    error,
    account,
    library,
    connector,
    deactivate,
    ...web3React
  } = useWeb3React();
  const location = useLocation();
  const { chain } = QueryString.parse(location.search || "");
  const [openSnackbar] = useAppSnackbar();
  const [balance, setBalance] = useState<string | number>(0);

  const connectWallet = useCallback(
    async (type: WalletConnectorTypes) => {
      const walletConnector = Connectors[type];
      try {
        if (walletConnector) {
          try {
            await activate(walletConnector);
            localStorage.setItem(
              LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE,
              type.toString()
            );
          } catch (error) {
            console.error("Failed to activate wallet connector:", error);
            openSnackbar("Failed to connect wallet. Please try again.");
            throw error;
          }

          // After successful connection, check and switch network if needed
          const web3 = new Web3(library.provider);
          const chainId = await web3.eth.getChainId();
          if (!SUPPORTED_CHAIN_IDS.includes(chainId)) {
            try {
              await web3.eth.requestAccounts();
              const provider = web3.eth.currentProvider;
              if (
                provider &&
                typeof provider !== "string" &&
                "request" in provider &&
                typeof provider.request === "function"
              ) {
                await provider.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: Web3.utils.toHex(Number(chain)) }],
                });
              } else {
                throw new Error("Invalid Web3 provider");
              }
            } catch (e: any) {
              if (e.code === 4902) {
                console.error(
                  "This network is not available in your wallet, please add it manually"
                );
              } else {
                console.error("Failed to switch network:", e);
              }
              // Re-throw the error to be handled by the caller
              throw e;
            }
          }
        } else {
          console.error("Invalid wallet connector");
        }
      } catch (error) {
        console.error("Error connecting wallet:", error);
      }
    },
    [activate, chain, library]
  );

  const switchNetwork = useCallback(
    async (chain: SupportedNetworks) => {
      if (!library) {
        throw new Error("No Web3 provider detected");
      }

      const web3 = new Web3(library.provider);

      try {
        await web3.eth.requestAccounts();
        if (
          web3.eth.currentProvider &&
          typeof web3.eth.currentProvider !== "string"
        ) {
          await (web3.eth.currentProvider as any).request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(Number(chain)) }],
          });
        } else {
          throw new Error("Invalid Web3 provider");
        }
      } catch (e: any) {
        if (e.code === 4902) {
          const appChain = SupportedChains.find((c) => c.chainId === chain);
          if (
            appChain &&
            web3.eth.currentProvider &&
            typeof web3.eth.currentProvider !== "string"
          ) {
            await (web3.eth.currentProvider as any).request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(Number(chain)),
                  chainName: appChain.label,
                  nativeCurrency: {
                    name: appChain.name,
                    symbol: appChain.symbol,
                    decimals: parseInt(appChain.decimals),
                  },
                  rpcUrls: appChain.rpcUrls,
                  blockExplorerUrls: appChain.blockExplorerUrls,
                },
              ],
            });
          }
        } else {
          console.error("Failed to switch network:", e);
          throw new Error("Failed to switch network");
        }
      }
    },
    [library]
  );

  const disconnectWallet = useCallback(() => {
    try {
      deactivate();
      localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE);
    } catch (error: any) {
      console.error(error.message);
    }
  }, [deactivate]);

  // refresh balance
  const refreshBaseTokenBalance = useCallback(() => {
    library
      ?.getBalance(account)
      .then((_balance: any) => {
        const balanceFromWei = Web3Utils.hexToNumberString(_balance._hex);
        setBalance(Web3Utils.fromWei(balanceFromWei));
      })
      .catch((err: any) => {
        console.error(err);
        setBalance(0);
      });
  }, [library, account]);

  useEffect(() => {
    if (error) {
      openSnackbar(error.message);
    }
    // disabled for  openSnackbar
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (account) {
      localStorage.setItem("account", account); // saving it for old functions
    } else {
      localStorage.removeItem("account"); // removing it for old functions
    }
  }, [account]);

  useEffect(() => {
    refreshBaseTokenBalance();
    library?.on("block", refreshBaseTokenBalance);
    return () => {
      library?.off("block", refreshBaseTokenBalance);
    };
  }, [library, refreshBaseTokenBalance]);

  return {
    active,
    error,
    account,
    library,
    chainId,
    balance,
    deactivate,
    ...web3React,
    connectWallet,
    switchNetwork,
    activate,
    disconnectWallet,
  };
};

export default useWallet;
