import { getEnvType } from "app-constants/env";
import { EnvType, SmartContractConfig, SupportedNetworks } from "types";

export const LARGE_APPROVAL_AMOUNT = 1000;
// degen contract address on BSC
// export const bsc_contract_address = getEnvType() === EnvType.development ? '0x632f263107868F20ba2b31BF84d5517148c5AaF6' : '0x32e43563d2df6a0a953c9a501c61b49e91bbbebb' //'0x345B0c2D5F24E10f9C4C641446082cBFa33D952f';  // BSC CONTRACT
// degen contract address on ETH
export const DegenSwapAddresses: SmartContractConfig = {
  [SupportedNetworks.mainnet]: "0xd85a4ac007cf06a4bf8daef180efe522096bceed",
  [SupportedNetworks.bsc]: "0x32e43563d2df6a0a953c9a501c61b49e91bbbebb",
  [SupportedNetworks.bsctestnetwork]:
    "0x632f263107868F20ba2b31BF84d5517148c5AaF6",
  [SupportedNetworks.sepolia]: "",
}; // = getEnvType() === EnvType.development ? '0xB2982a10cEB968137977dcAd6A945cd714f5dEdc' : '0xd85a4ac007cf06a4bf8daef180efe522096bceed' //'0xdB311dE19163F1730B8DeBE4C7C610866b659405'; // ETH CONTRACT
//test
export const tBNB = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";
export const tETH = "0xc778417E063141139Fce010982780140Aa0cD5Ab";
export const BNBBSC = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";

// export const DEGEN_BACKEND_API_URL: NetworkUrls = {
//     [SupportedNetworks.mainnet]: 'https://api.degenswap.io/',
//     [SupportedNetworks.bsc]: 'https://api.degenswap.io/',
//     [SupportedNetworks.bsctestnetwork]: 'http://52.70.198.55:5000/api/DEGEN/',
// } //  = getEnvType() === EnvType.development ? 'http://52.70.198.55:5000/api/DEGEN/' : 'https://api.degenswap.io/'

export const WETH =
  getEnvType() === EnvType.development
    ? tETH
    : "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"; /// main net
export const WBNB = getEnvType() === EnvType.development ? tBNB : BNBBSC;

export const MAIN_ZERO_ADDRESS = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const ADDRESS_ONE = "0x0000000000000000000000000000000000000001";
export const UNISWAP_FACTORY = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
export const UNISWAP_ROUTER = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
export const SUSHISWAP_ROUTER =
  getEnvType() === EnvType.development
    ? "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
    : "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F";
export const PANCAKE_ROUTER =
  getEnvType() === EnvType.development
    ? "0xD99D1c33F9fC3444f8101754aBC46c52416550D1"
    : "0x10ED43C718714eb63d5aA57B78B54704E256024E";
export const PANCAKE_FACTORY =
  getEnvType() === EnvType.development
    ? "0x6725F303b657a9451d8BA641348b6761A6CC7a17"
    : "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
export const SUSHISWAP_FACTORY =
  getEnvType() === EnvType.development
    ? "0xc35DADB65012eC5796536bD9864eD8773aBc74C4"
    : "0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac";

export const BNB_USDT =
  getEnvType() === EnvType.development
    ? "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd"
    : "0x55d398326f99059fF775485246999027B3197955";
export const ETH_USDT =
  getEnvType() === EnvType.development
    ? "0x110a13FC3efE6A245B50102D2d79B3E76125Ae83"
    : "0xdAC17F958D2ee523a2206206994597C13D831ec7";

export const ethConnectionUrl =
  getEnvType() === EnvType.development
    ? "https://ropsten.infura.io/v3/e3706a59ed38418095f619d56df648e0"
    : "https://mainnet.infura.io/v3/e3706a59ed38418095f619d56df648e0";
export const bnbConnectionUrl =
  getEnvType() === EnvType.development
    ? "https://data-seed-prebsc-1-s1.binance.org:8545"
    : "https://bsc-dataseed.binance.org";

export const BSC_ReimbursementContract =
  "0xd26132cca2d9e0b4901c7ad4de2b16364624f85a";
export const ETH_ReimbursementContract =
  "0x96d1e9d25bd949b6811e2844aca63cb3cfce68af";
export const MATIC_ReimbursementContract =
  "0x1368452bfb5cd127971c8de22c58fbe89d35a6bf";
export const ETH_StakingContract = "0xf421c80b08891015f73cb8d19aa5dfa01228e39f";
export const BNB_StakingContract = "0x59188D84dDdfAB858315a4e99Bc5a3aaaF6E768B";
// JNTR contract address in Test BNB network.
export const JNTR_TB_ContractAddress =
  "0x001667842Cc59CAdB0A335bf7c7f77b3C75f41c2";
export const JNTR_E_ContractAddress =
  "0x1368452Bfb5Cd127971C8DE22C58fBE89D35A6BF";
export const JNTR_B_ContractAddress =
  "0x3c037c4c2296f280bb318d725d0b454b76c199b9";

export const BnBCommonTokenSymbols = [
  "BNB",
  "USDT",
  "WBNB",
  "DAI",
  "BAI",
  "BUSD",
  "DEGEN",
];

export const EthCommonTokenSymbols = [
  "ETH",
  "DAI",
  "USDT",
  "USDC",
  "WBTC",
  "WETH",
];
