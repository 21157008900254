import DegenTokenImage from "Assets/images/Degen_token.png";
import {
  BNBBSC,
  DEGEN_TOKEN_ADDRESS_BSC,
  DMC_TOKEN_ADDRESS,
  tBNB,
} from "app-constants";
import bnb from "Assets/images/bnb-icon.png";

const lowerDegenAddress = DEGEN_TOKEN_ADDRESS_BSC.toLowerCase();

export const TokenAlterImages = {
  [lowerDegenAddress]: DegenTokenImage,
  [String(tBNB).toLowerCase()]: bnb,
  [String(BNBBSC).toLowerCase()]: bnb,
  [String(DMC_TOKEN_ADDRESS[1]).toLowerCase()]: "/images/dmc-token.png",
};
