import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import moment from "moment";
import { BsQuestionCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";

import TransactionModals from "Component/popup/transactions/transaction-popups";
import { AppRouters } from "app-constants/app-routers";
import { BackButton } from "Component/core/elements/Buttons";
import AppCheckbox from "Component/core/elements/AppCheckbox";
import TokenIcon from "Component/TokenIcon";
import InfoCard from "../../elements/InfoCard";
import {
  VESTING_FOR_PRINCIPAL,
  REDEMPTION_LEFTOVER,
  VESTING_FOR_PROFIT,
  BOND_TYPE,
  VESTING_PERIOD,
  NETWORKS,
  ZERO_ADDRESS,
  MainTokenSymbols,
} from "app-constants";
import ApproveSection from "../../elements/ApproveSection";
import {
  useAppChain,
  useFailedInfoModal,
  usePriceBySymbol,
  useProcessingModal,
  useTransactionModal,
  useWeb3,
} from "hooks";
import useWallet from "hooks/useWallet";
import {
  createBond,
  prevStep,
  addProjectInformation,
  setStep as setBondStep,
} from "redux/reducers/bond/actions";
import tooltip from "app-constants/tooltip-texts";
import * as S from "./styled";
import Web3Helpers from "helper/Web3Helpers";
import { BondSelector } from "redux/selectors";
import AppLink from "Component/AppLink";
import { getCurrentBondContract } from "redux/actions";
import { isEqAddr, sleep } from "helper";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  BondSContentTable,
  BondSelectPair,
  BondTabTitle,
  EditButton,
  SContentSubTable,
} from "Component/core/elements/IBO-SharedComponents";
import AppTooltip from "Component/AppTooltip";
import { TokenBalanceSpan } from "Component/core/elements/others/global-components";
import { Input, TextField } from "@mui/material";
import { RadioButton } from "Component/core/elements/RadioButton";
import {
  InputGroup,
  InputGroupControl,
} from "Component/core/elements/InputGroup";
import Decimal from "decimal.js";
import BN from "bn.js";
import { simpleAmountInString } from "helper/math";

const FINAL_STEP = 1;

function DeployBondPool() {
  const { appChainId } = useAppChain();
  const network = useMemo(() => {
    return NETWORKS[appChainId];
  }, [appChainId]);
  const web3 = useWeb3();
  const [openLoadingModal, closeLoadingModal] = useProcessingModal();
  const { account } = useWallet();
  const bondState = useAppSelector(BondSelector);
  const {
    pairToken,
    tokenInfo,
    tokenRatio,
    token,
    amountOfSupply,
    raisingTarget,
  } = bondState;
  const history = useHistory();

  const [isTokenEnabled, setIsTokenEnabled] = useState(false);
  const [isPairTokenEnabled, setIsPairTokenEnabled] = useState(false);
  const [isBondDeployed, setIsBondDeployed] = useState(false);

  const [isBaselineApproved, setIsBaselineApproved] = useState(false);
  const [isShowBaseLineSection, setIsShowBaseLineSection] = useState(true);

  const onAfterTRMClose = useCallback(() => {
    history.push(`${AppRouters.IBO_BONDS_PAGE}${history.location.search}`);
  }, [history?.location?.search]);

  const dispatch = useAppDispatch();
  const currentBondContract = useMemo(
    () => dispatch(getCurrentBondContract()),
    []
  );
  const {
    open: openTRModal,
    close: closeTRModal,
    hash: TRMHash,
    isOpen: isTRMOpen,
  } = useTransactionModal({ afterClose: onAfterTRMClose });
  const [openFTModal] = useFailedInfoModal();
  const priceInUsd = usePriceBySymbol(pairToken?.symbol);
  const [step, setStep] = useState(0); // 0 (token0 enabled) | 1 (token 1 enabled) | 2 (deploy a bond and new pool)

  const shieldPeriod = useMemo(() => {
    return moment(bondState.shieldPeriod).format("LL");
  }, [bondState.shieldPeriod]);

  const [isInviteCode, setInviteCodeEnable] = useState(true);

  const handleOnChangeInvestCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _hasInvestCode = e.target.value === "1";
    setInviteCodeEnable(_hasInvestCode);
  };

  const ref = useRef<null | HTMLInputElement>(null);
  const [inviteCode, setInviteCode] = useState("");

  const handleChangeInvestCodeInput = (e: any) => {
    setInviteCode(e.target.value);
  };

  const approveToken = async (
    _tokenAddress: string,
    _tokenDecimals: string | number,
    _amount: string | number,
    _spender: string
  ): Promise<void> => {
    const w3h = new Web3Helpers(web3, account);
    await w3h.smartApproveToken(
      _tokenAddress,
      _tokenDecimals,
      _amount,
      _spender,
      account as string,
      network.W_TOKEN_ADDRESS
    );
  };

  const approveSupply = async () => {
    return await approveToken(
      token,
      tokenInfo.decimals,
      // new Decimal(amountOfSupply)
      //   .times(new Decimal(10).pow(tokenInfo.decimals))
      //   .toString(),
      simpleAmountInString(amountOfSupply, +tokenInfo.decimals),
      currentBondContract
    );
  };

  const approveTargetSupply = async () => {
    // const amount = new Decimal(+raisingTarget)
    //   .div(+amountOfSupply)
    //   .toFixed(Number(pairToken.decimals));
    const oneToken = simpleAmountInString(1, +tokenInfo.decimals);
    const sA = new BN(+amountOfSupply, 10);
    const tA = new BN(+raisingTarget, 10);
    const oT = new BN(oneToken, 10);

    const amount = tA.mul(oT).div(sA).toString();
    return await approveToken(
      pairToken.address,
      pairToken.decimals,
      amount,
      currentBondContract
    );
  };

  const createBondPool = async () => {
    let result;
    try {
      openLoadingModal("Creating a new bond....");
      result = await dispatch(createBond());
      openTRModal(result.transactionHash);
      setIsBondDeployed(true);
      // @ts-ignore
    } catch (error: Error) {
      console.error(error);
      return openFTModal(error.response?.data || error.message || error);
    } finally {
      closeLoadingModal();
    }
    await sleep(1000);
    try {
      openLoadingModal("Uploading Project Information...");
      const { classId, nonceId } = result.events.CreateOffer.returnValues;
      const customBondId = `${classId}-${nonceId}`;
      await dispatch(addProjectInformation(customBondId));
      // @ts-ignore
    } catch (error: Error) {
      const err = error.response?.data || error.message || error;
      // openFTModal(
      //   err,
      //   "Failed while uploading project information. \n But your bond has been created.",
      // );
    } finally {
      closeLoadingModal();
    }
  };

  const handleOnClickApprove = () => {
    const delay = (cb: () => void) => {
      setTimeout(async () => {
        cb();
      }, 500);
    };

    if (step === 0) {
      setStep(1);
      delay(() => {
        setIsShowBaseLineSection(false);
        setIsBaselineApproved(true);
      });
    } else if (step === 1) {
      setStep(2);
      delay(() => {
        setIsShowBaseLineSection(true);
      });
    } else {
      setStep(3);
    }
  };

  const handleOnClickBack = () => {
    dispatch(prevStep());
  };

  const handleOnClickTokenEnabled = async () => {
    try {
      openLoadingModal();
      await approveSupply();
      setIsTokenEnabled(true);
      setIsShowBaseLineSection(false);
      // setIsShowTerms(true)
      // @ts-ignore
    } catch (error: Error) {
      console.error(error);
      openFTModal(error.response?.data || error.message || error);
    } finally {
      closeLoadingModal();
    }
  };
  const handleOnClickPairTokenEnabled = async () => {
    try {
      openLoadingModal();
      await approveTargetSupply();
      setIsPairTokenEnabled(true);
      // @ts-ignore
    } catch (error: Error) {
      console.error(error);
      openFTModal(error.response?.data || error.message || error);
    } finally {
      closeLoadingModal();
    }
  };
  const goToProjectInfo = () => {
    dispatch(setBondStep(0));
  };
  const goToBondTerms = () => {
    dispatch(setBondStep(1));
  };
  const goToFundraisingTerms = () => {
    dispatch(setBondStep(2));
  };

  useEffect(() => {
    if (
      isEqAddr(pairToken.address, ZERO_ADDRESS) ||
      isEqAddr(pairToken.address, network.W_TOKEN_ADDRESS)
    )
      setIsPairTokenEnabled(true);
    else {
      setIsPairTokenEnabled(false);
    }
  }, [pairToken, network]);

  useEffect(() => {
    (async () => {
      const w3h = new Web3Helpers(web3, account);
      const isAllowed = await w3h.findAllowedAmount(
        token,
        +amountOfSupply,
        currentBondContract
      );
      setIsTokenEnabled(isAllowed);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const w3h = new Web3Helpers(web3, account);
      const amount = +raisingTarget / +amountOfSupply;
      const isAllowed = await w3h.findAllowedAmount(
        pairToken.address,
        amount,
        currentBondContract
      );
      setIsPairTokenEnabled(isAllowed);
    })();
  }, []);

  return (
    <React.Fragment>
      <S.BondPoolBox>
        <div className="py-20">
          <BackButton onClick={handleOnClickBack} />
        </div>
        <div>
          <BondTabTitle>Project Information</BondTabTitle>
          <BondSContentTable className="highlighted-border-bottom">
            <tbody>
              <tr>
                <td className="vertical-align-middle">
                  <span>Place your token contract</span>
                </td>
                <td className="vertical-align-middle">
                  <div className="d-flex">
                    <span>{bondState.token.slice(0, -15)}...</span>
                    <span className="ml-20 text-uppercase">
                      {bondState.tokenInfo.symbol}
                    </span>
                  </div>
                </td>
                <td className="vertical-align-middle">
                  <span>Select a pair</span>
                </td>
                <td className="d-flex align-items-center justify-content-between position-relative w-full">
                  <BondSelectPair>
                    <TokenIcon
                      className="md"
                      byAddress={bondState.tokenInfo.address}
                    />
                    <span>{bondState.tokenInfo.symbol}</span>
                    <span>&nbsp;{"<>"}&nbsp;</span>
                    <TokenIcon
                      className="md"
                      byAddress={bondState.pairToken.address}
                    />
                    <span>{bondState.pairToken.symbol}</span>
                  </BondSelectPair>
                  <div>
                    <EditButton onClick={goToProjectInfo} />
                  </div>
                </td>
              </tr>
            </tbody>
          </BondSContentTable>
          <BondSContentTable className="highlighted-border-bottom">
            <tbody>
              <tr>
                <td className="vertical-align-middle">
                  <span>Add a logo</span>
                </td>
                <td className="vertical-align-middle">
                  <span>{bondState.logo.preview.replace(/blob:/gi, "")}</span>
                </td>
                <td className="vertical-align-middle"></td>
                <td className="w-full vertical-align-middle d-flex justify-content-between align-items-center">
                  <S.SPreviewLogo
                    className="bg-white rounded-circle"
                    src={bondState.logo.preview}
                    alt="logo"
                  />
                  <div>
                    <EditButton onClick={goToProjectInfo} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Add your website</span>
                </td>
                <td>
                  <S.WebSiteLinkP title={bondState.website}>
                    {bondState.website}
                  </S.WebSiteLinkP>
                </td>
                <td>
                  <span>Add link to whitepaper</span>
                </td>
                <td>
                  <div className="d-flex justify-content-between">
                    <S.WebSiteLinkP title={bondState.whitepaper}>
                      {bondState.whitepaper}
                    </S.WebSiteLinkP>
                    <div>
                      <EditButton onClick={goToProjectInfo} />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </BondSContentTable>
          <BondSContentTable className="highlighted-border-bottom">
            <tbody>
              <tr>
                <td>
                  <span>IBO start date</span>
                </td>
                <td>
                  <span>{moment(bondState.startIBODate).format("LL")}</span>
                </td>
                <td>
                  <span>IBO end date</span>
                </td>
                <td className="w-full">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{moment(bondState.endIBODate).format("LL")}</span>
                    <div>
                      <EditButton onClick={goToProjectInfo} />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </BondSContentTable>
        </div>

        <div>
          <BondTabTitle>Bond pool terms</BondTabTitle>
          <BondSContentTable className="highlighted-border-bottom">
            <tbody>
              {/* <tr>
              <td>
                <span>Bond type</span>
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
              </td>
              <td>
                <span>{bondState.bondType.label}</span>
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
              </td>
              <td className='w-full'></td>
            </tr> */}
              <tr
                hidden={
                  bondState.bondType.value === BOND_TYPE.IMPERMANENT.value
                }
              >
                <td>
                  <span>Leftover tokens</span>
                </td>
                <td>
                  <span>
                    {bondState.leftoverBurn === REDEMPTION_LEFTOVER.BURN
                      ? "Burn"
                      : "Refund To Project"}
                  </span>
                  <BsQuestionCircleFill
                    className="fas helpIco"
                    data-type="light"
                    data-html="true"
                    data-class="data-tooltip"
                    data-tip={tooltip(
                      bondState.leftoverBurn === REDEMPTION_LEFTOVER.BURN
                        ? "Bond_Pool_Burn"
                        : "Refund To Project"
                    )}
                  />
                </td>
                <td className="w-full">
                  <div className="w-full d-flex justify-content-end">
                    <EditButton onClick={goToBondTerms} />
                  </div>
                </td>
              </tr>
            </tbody>
          </BondSContentTable>
          <BondSContentTable className="highlighted-border-bottom">
            <tbody>
              <tr>
                <td>
                  <span>Principal vesting</span>
                </td>
                <td>
                  {bondState.vestingForPrincipal.type ===
                  VESTING_FOR_PRINCIPAL.CLIFF ? (
                    <>
                      <span>Cliff</span>
                      <BsQuestionCircleFill
                        className="fas helpIco"
                        data-type="light"
                        data-html="true"
                        data-class="data-tooltip"
                        data-tip={tooltip("Principal Cliff")}
                      />
                    </>
                  ) : (
                    <>
                      <span>Graded</span>
                    </>
                  )}
                </td>
                {bondState.vestingForPrincipal.type ===
                VESTING_FOR_PRINCIPAL.CLIFF ? (
                  <>
                    <td>
                      <span>After</span>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="text-white">
                          {bondState.vestingForPrincipal.afterValue}&nbsp;
                          {bondState.vestingForPrincipal.afterOption.label}
                        </span>
                        <div className="w-full d-flex justify-content-end">
                          <EditButton onClick={goToBondTerms} />
                        </div>
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <span>Portion</span>
                      <BsQuestionCircleFill
                        className="fas helpIco"
                        data-type="light"
                        data-html="true"
                        data-class="data-tooltip"
                        data-tip={tooltip("Bond_Token_Potion")}
                      />
                    </td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="text-white">
                          {bondState.vestingForPrincipal.portionValue}%
                        </span>
                        <div className="w-full d-flex justify-content-end">
                          <EditButton onClick={goToBondTerms} />
                        </div>
                      </div>
                    </td>
                  </>
                )}
              </tr>
              <tr
                hidden={
                  bondState.vestingForPrincipal.type ===
                  VESTING_FOR_PRINCIPAL.CLIFF
                }
              >
                <td></td>
                <td></td>
                <td>
                  <span>Every</span>
                  <BsQuestionCircleFill
                    className="fas helpIco"
                    data-type="light"
                    data-html="true"
                    data-class="data-tooltip"
                    data-tip={tooltip("Bond_Token_Every")}
                  />
                </td>
                <td className="text-uppercase">
                  <span>
                    {bondState.vestingForPrincipal.everyValue}{" "}
                    {bondState.vestingForPrincipal.everyOption.label}
                  </span>
                </td>
              </tr>
              {bondState.bondType.value === BOND_TYPE.IMPERMANENT.value ? (
                <>
                  <tr>
                    <td>
                      <span>Vesting period</span>
                    </td>
                    <td>
                      <span>
                        {bondState.vestingPeriod.type === VESTING_PERIOD.CLIFF
                          ? "Cliff"
                          : "Graded"}
                      </span>
                      <BsQuestionCircleFill
                        className="fas helpIco"
                        data-type="light"
                        data-html="true"
                        data-class="data-tooltip"
                        data-tip={"Coming Soon"}
                      />
                    </td>
                    {bondState.vestingPeriod.type === VESTING_PERIOD.CLIFF ? (
                      <>
                        <td>
                          <span>After</span>
                        </td>
                        <td className="text-white">
                          <span>
                            {bondState.vestingPeriod.afterValue}&nbsp;
                            {bondState.vestingPeriod.afterOption.label}
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <span>Portion</span>
                          <BsQuestionCircleFill
                            className="fas helpIco"
                            data-type="light"
                            data-html="true"
                            data-class="data-tooltip"
                            data-tip={tooltip("Bond_Token_Potion")}
                          />
                        </td>
                        <td className="text-white">
                          <span>{bondState.vestingPeriod.portionValue}%</span>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr
                    hidden={
                      bondState.vestingPeriod.type === VESTING_PERIOD.CLIFF
                    }
                  >
                    <td></td>
                    <td></td>
                    <td>
                      <span>Every</span>
                      <BsQuestionCircleFill
                        className="fas helpIco"
                        data-type="light"
                        data-html="true"
                        data-class="data-tooltip"
                        data-tip={tooltip("Bond_Token_Every")}
                      />
                    </td>
                    <td className="text-uppercase">
                      <span>
                        {bondState.vestingPeriod.everyValue}{" "}
                        {bondState.vestingPeriod.everyOption.label}
                      </span>
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )}
              <tr>
                <td>
                  <span>Profit vesting</span>
                </td>
                <td>
                  <span>
                    {bondState.vestingForProfit.type ===
                    VESTING_FOR_PROFIT.CLIFF
                      ? "Cliff"
                      : "Graded"}
                  </span>
                </td>

                {bondState.vestingForProfit.type ===
                VESTING_FOR_PROFIT.CLIFF ? (
                  <>
                    <td>
                      <span>After</span>
                    </td>
                    <td className="text-white text-muted">
                      <span>
                        {bondState.vestingForProfit.afterValue}&nbsp;
                        {bondState.vestingForProfit.afterOption.label}
                      </span>
                      <div className="mt-10">
                        <AppCheckbox
                          label={
                            <span className="fs-sm">
                              Start profits redemption after principal
                              <BsQuestionCircleFill
                                className="fas helpIco"
                                data-type="light"
                                data-html="true"
                                data-class="data-tooltip"
                                data-tip={"Coming Soon"}
                              />
                            </span>
                          }
                          checked={bondState.vestingForProfit.isStartProfit}
                          readOnly
                        />
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <span>Portion</span>
                      <BsQuestionCircleFill
                        className="fas helpIco"
                        data-type="light"
                        data-html="true"
                        data-class="data-tooltip"
                        data-tip={tooltip("Bond_Token_Potion")}
                      />
                    </td>
                    <td className="text-white">
                      <span>{bondState.vestingForProfit.portionValue}%</span>
                    </td>
                  </>
                )}
              </tr>
              <tr
                hidden={
                  bondState.vestingForProfit.type === VESTING_FOR_PROFIT.CLIFF
                }
              >
                <td></td>
                <td></td>
                <td>
                  <span>Every</span>
                  <BsQuestionCircleFill
                    className="fas helpIco"
                    data-type="light"
                    data-html="true"
                    data-class="data-tooltip"
                    data-tip={tooltip("Bond_Token_Every")}
                  />
                </td>
                <td className="text-uppercase">
                  <span>
                    {bondState.vestingForProfit.everyValue}&nbsp;
                    {bondState.vestingForProfit.everyOption.label}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Prepayment penalty</span>
                  <BsQuestionCircleFill
                    className="fas helpIco"
                    data-type="light"
                    data-html="true"
                    data-class="data-tooltip"
                    data-tip={tooltip("PREPAYMENT_PENALTY")}
                  />
                </td>
                <td>
                  <span>{bondState.prepaymentPenalty ? "Yes" : "No"}</span>
                </td>
                <td>
                  <span>Dynamic penalty</span>
                  <BsQuestionCircleFill
                    className="fas helpIco"
                    data-type="light"
                    data-html="true"
                    data-class="data-tooltip"
                    data-tip={tooltip("Dynamic_Penalty")}
                  />
                </td>
                <td>
                  <span>{bondState.dynamicPenalty}%</span>
                </td>
              </tr>
            </tbody>
          </BondSContentTable>
        </div>
        <div>
          <BondTabTitle>Fundraising terms</BondTabTitle>
          <BondSContentTable className="highlighted-border-bottom">
            <tbody>
              <tr>
                <td>
                  <span>Supply Amount</span>
                  <BsQuestionCircleFill
                    className="fas helpIco"
                    data-type="light"
                    data-html="true"
                    data-class="data-tooltip"
                    data-tip={tooltip("Bond_Pool_Supply_Amount")}
                  />
                </td>
                <td>
                  <span>
                    {bondState.amountOfSupply} {tokenInfo.symbol}
                  </span>
                </td>
                <td className="vertical-align-top pt-15">
                  <span>Target raise</span>
                  <BsQuestionCircleFill
                    className="fas helpIco"
                    data-type="light"
                    data-html="true"
                    data-class="data-tooltip"
                    data-tip={tooltip("Bond_Pool_Fundraise_Target")}
                  />
                </td>
                <td>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="d-flex align-items-center pb-0">
                        <TokenIcon
                          className="md-2"
                          byAddress={pairToken.address}
                          width={30}
                        />
                        <span className="ml-5">
                          {bondState.raisingTarget} {pairToken.symbol}
                        </span>
                      </p>
                      <p className="d-flex justify-content-between fs-sm">
                        <span>
                          1 {tokenInfo.symbol} {"<>"} {tokenRatio}{" "}
                          {pairToken.symbol}
                        </span>
                        <span>[${priceInUsd}]</span>
                      </p>
                    </div>
                    <div className="w-full d-flex justify-content-end">
                      <EditButton
                        className="mt-5"
                        onClick={goToFundraisingTerms}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </BondSContentTable>
          <BondSContentTable className="highlighted-border-bottom">
            <tbody>
              <tr>
                <td className="vertical-align-top pt-18">
                  <span>Fees structure</span>
                </td>
                <td className="vertical-align-top pt-18">
                  <p className="w-max-300">
                    {bondState.feeStructure.label.replace(
                      "%%%",
                      MainTokenSymbols[appChainId]
                    )}
                    <BsQuestionCircleFill
                      className="fas helpIco"
                      data-type="light"
                      data-html="true"
                      data-class="data-tooltip"
                      data-tip={bondState.feeStructure.tooltipText}
                    />
                  </p>
                </td>
                <td colSpan={2} className="w-full">
                  <SContentSubTable className="w-full">
                    <tbody>
                      <tr>
                        <td>
                          <span className="lh-base">
                            Select existing DEX
                            <br />
                            with {tokenInfo.symbol} / {pairToken.symbol} pool
                          </span>
                        </td>
                        <td>
                          <div className="d-flex justify-content-between">
                            {/* <span>{bondState.dexWithPool.title}</span> */}
                            <AppLink to={"/swap"} className="text-muted w-full">
                              DEREX exchange
                            </AppLink>
                            <EditButton onClick={goToFundraisingTerms} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Staking period for users</span>
                          <BsQuestionCircleFill
                            className="fas helpIco"
                            data-type="light"
                            data-html="true"
                            data-class="data-tooltip"
                            data-tip={tooltip("Staking period for users")}
                          />
                        </td>
                        <td>
                          <span className="text-uppercase">
                            {bondState.stakingPeriod.value}{" "}
                            {bondState.stakingPeriod.timeOption.label}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>APY for users</span>
                          <BsQuestionCircleFill
                            className="fas helpIco"
                            data-type="light"
                            data-html="true"
                            data-class="data-tooltip"
                            data-tip={tooltip("Bond_Pool_APY_FOR_USERS")}
                          />
                        </td>
                        <td>
                          <span>{bondState.apyForUsers}%</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Dumper Shield for users</span>
                          <BsQuestionCircleFill
                            className="fas helpIco"
                            data-type="light"
                            data-html="true"
                            data-class="data-tooltip"
                            data-tip={tooltip("Dumper Shield for users")}
                          />
                        </td>
                        <td>
                          <span>
                            {bondState.isDumperShieldForUsers ? "Yes" : "No"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Choose a shield period</span>
                          <BsQuestionCircleFill
                            className="fas helpIco"
                            data-type="light"
                            data-html="true"
                            data-class="data-tooltip"
                            data-tip={tooltip("Bond_Pool_Choose_Shield_Period")}
                          />
                        </td>
                        <td>
                          <span>{shieldPeriod}</span>
                        </td>
                      </tr>
                    </tbody>
                  </SContentSubTable>
                </td>
              </tr>
            </tbody>
          </BondSContentTable>
          <BondSContentTable className="highlighted-border-bottom">
            <tbody>
              <tr>
                <td>
                  <span>Investment limit per wallet</span>
                </td>
                <td></td>
                <td colSpan={2} className="w-full">
                  <SContentSubTable className="w-full">
                    <tbody>
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              {bondState.investmentLimit.isSet ? "Yes" : "No"}
                            </span>
                            <EditButton onClick={goToFundraisingTerms} />
                          </div>
                        </td>
                      </tr>
                      {bondState.investmentLimit.isSet && (
                        <>
                          <tr>
                            <td>
                              <span>Minimum</span>
                            </td>
                            <td>${bondState.investmentLimit.minimum}</td>
                          </tr>
                          <tr>
                            <td>
                              <span>Maximum</span>
                            </td>
                            <td>${bondState.investmentLimit.maximum}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </SContentSubTable>
                </td>
              </tr>
            </tbody>
          </BondSContentTable>
          <div className="highlighted-border-bottom">
            <S.SInvestCodeWrap>
              <S.SInvestCodeTitle>
                <span className="text-primary w-1/2">
                  Please enter your invite code to apply for up to $100K grant
                </span>
              </S.SInvestCodeTitle>
              <S.SRadioButtonGroup>
                <RadioButton
                  id="invite-code-enable"
                  name="invite-code-enable"
                  className="mr-5 d-flex align-items-center text-xl-center w-64"
                  label={
                    <InputGroup
                      className={`w-[300px] border-highlight-shadow-gray-1 ml-10 mr-24`}
                    >
                      <InputGroupControl
                        disabled={!isInviteCode}
                        ref={ref}
                        value={inviteCode}
                        onChange={handleChangeInvestCodeInput}
                        className="py-10"
                        placeholder="Type the invite code here"
                      />
                    </InputGroup>
                  }
                  value="1"
                  checked={isInviteCode === true}
                  onChange={handleOnChangeInvestCode}
                />
                <RadioButton
                  id="invite-code-enable"
                  name="invite-code-enable"
                  className="mr-5 d-flex align-items-center"
                  label={
                    <label
                      htmlFor="Redemption-Leftover-Burn"
                      className="ml-10 pt-5"
                    >
                      I don&apos;t have an invite code
                    </label>
                  }
                  value="0"
                  checked={isInviteCode === false}
                  onChange={handleOnChangeInvestCode}
                />
              </S.SRadioButtonGroup>
            </S.SInvestCodeWrap>
          </div>
          <ApproveSection
            className={`${isShowBaseLineSection ? "" : "d-none"}`}
            approved={isBaselineApproved}
            onClickApproveButton={handleOnClickApprove}
          >
            {({ isVaildate }) => (
              <>
                <h1 className="fs-3xl fw-bold">
                  Approve all terms and baseline pool ratio
                </h1>
                <h4 className="text-muted fs-md">
                  (Token face value{" "}
                  <TokenBalanceSpan prefix="$" digits={6}>
                    {priceInUsd * tokenRatio}
                  </TokenBalanceSpan>
                  )
                </h4>
                <p
                  className={`text-title ${
                    isVaildate ? "text-primary" : "text-danger"
                  } my-20`}
                >
                  Type the word <span className="text-white">APPROVE</span> to
                  set the terms and the secure floor at&nbsp;
                  <span className="text-white">
                    1 {tokenInfo.symbol} {"<>"}{" "}
                    {/* <TokenBalanceSpan digits={6}> */}
                    {tokenRatio.toFixed(4).toLocaleString()}
                    {/* </TokenBalanceSpan> */} {pairToken.symbol}
                  </span>
                  <BsQuestionCircleFill
                    className="fas helpIco"
                    data-type="light"
                    data-html="true"
                    data-class="data-tooltip"
                    data-tip={tooltip("Bond_Approve_Terms")}
                  />
                </p>
              </>
            )}
          </ApproveSection>
          <div className={`${step === FINAL_STEP ? "" : "d-none"}`}>
            <S.SInfoCardWrap>
              <InfoCard
                num={1}
                className={!isTokenEnabled ? "active" : ""}
                title={
                  isTokenEnabled
                    ? `${tokenInfo.symbol} enabled`
                    : `enable ${tokenInfo.symbol}`
                }
                isCompleted={isTokenEnabled}
                onClick={handleOnClickTokenEnabled}
                // @ts-ignore
                as="button"
                disabled={isTokenEnabled}
              />
              <InfoCard
                num={2}
                className={
                  isTokenEnabled && !isPairTokenEnabled ? "active" : ""
                }
                title={
                  isPairTokenEnabled
                    ? `${pairToken.symbol} enabled`
                    : `enable ${pairToken.symbol}`
                }
                isCompleted={isPairTokenEnabled}
                onClick={handleOnClickPairTokenEnabled}
                // @ts-ignore
                as="button"
                disabled={!isTokenEnabled || isPairTokenEnabled}
              />
              <InfoCard
                num={3}
                className={isTokenEnabled && isPairTokenEnabled ? "active" : ""}
                title={"deploy a bond and new pool"}
                isCompleted={isBondDeployed}
                onClick={createBondPool}
                // @ts-ignore
                as="button"
                disabled={
                  !isTokenEnabled || !isPairTokenEnabled || isBondDeployed
                }
              />
            </S.SInfoCardWrap>
            <p className="mt-20 fs-sm text-center text-danger">
              As default the pool will be locked as private with complete halt
              0% slippage to prevent price movement. once IBO is over those
              restrictions will be remove.
            </p>
          </div>
        </div>
      </S.BondPoolBox>
      <AppTooltip />
      <TransactionModals.Success
        isOpen={isTRMOpen}
        close={closeTRModal}
        hash={TRMHash}
      />
    </React.Fragment>
  );
}

export default DeployBondPool;
