import { AppButton } from 'Component/core/elements/Buttons'
import styled from 'styled-components'

export const SLogo = styled.img`
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  height: 70px;
  max-height: 70px;
  background-color: black;
  border-radius: 100%;

  &.placeholder {
    padding: 15px;
  }
`

export const SFeeStructureTable = styled.table`
  & > tbody > tr > td {
    padding: 20px 0;
    width: 50%;

    & > label {
      max-width: 250px;
    }
  }
`

export const BecomeAffiliateButton = styled(AppButton)`
  width: auto;
  color: ${({ theme }) => theme.colorGrey6};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: normal;
  padding: 10px 25px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colorGrey5};
  background-color: unset;
  box-shadow: none;
  &:hover {
    background-color: unset;
  }
`
