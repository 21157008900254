import React, { useContext, useMemo } from "react"
import useThemeConfigName from "hooks/useThemeConfigName"
import { DefaultTheme } from "styled-components"
import { Nav } from "types"
import { useAppLogoName } from "./Headers/AppLogos"
import { ThemeContext } from "styled-components"

type Props = {
    navs: Nav[],
    children: React.ReactNode,
}
const MainBox = ({ navs, children }: Props): JSX.Element => {
    const themeConfigName = useThemeConfigName()
    const logoName = useAppLogoName(navs)
    const appTheme = useContext<DefaultTheme>(ThemeContext)
    appTheme.pageBgImage

    const logoUrl = useMemo(() => {
        if (themeConfigName === 'lpInsure') {
            return appTheme.pageBgImage;
        }
        return `/images/${logoName}-shield-bg.png`
    }, [themeConfigName, logoName, appTheme.pageBgImage])

    return (
        <div style={{
            margin: "150px 0 0 0",
            backgroundImage: `url('${logoUrl}')`,
            backgroundColor: `${appTheme.pageBgColor}`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
            {children}
        </div>
    )
}


export default MainBox;