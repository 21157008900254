import { Link, LinkProps, useLocation } from "react-router-dom";
import QueryString from "query-string";

/**
 * @description This Component Add chain query to other links if chain exists
 */
interface IAppLinkProps extends LinkProps {
  to: string;
  children: any;
}
function AppLink({ to, children, ...props }: IAppLinkProps): JSX.Element {
  const location = useLocation();
  const { chain } = QueryString.parse(location.search || "");
  let href = "";

  const smartLink = (link: string) => {
    if (String(to).startsWith("#")) {
      return link + to;
    } else {
      return to + link;
    }
  };

  if (chain && to && to.search(/\?/i) > -1) {
    href = smartLink("&chain=" + chain);
  } else if (chain && to) {
    href = smartLink("?chain=" + chain);
  }

  return (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
}

export default AppLink;
