import React from 'react'
import chain1 from 'Assets/images/chains/chain-1.png'
import chain10 from 'Assets/images/chains/chain-10.png'
import chain11 from 'Assets/images/chains/chain-11.png'
import chain12 from 'Assets/images/chains/chain-12.png'
import chain13 from 'Assets/images/chains/chain-13.png'
import chain14 from 'Assets/images/chains/chain-14.png'
import chain15 from 'Assets/images/chains/chain-15.png'
import chain16 from 'Assets/images/chains/chain-16.png'
import chain17 from 'Assets/images/chains/chain-17.png'
import chain18 from 'Assets/images/chains/chain-18.png'
import chain19 from 'Assets/images/chains/chain-19.png'
import chain2 from 'Assets/images/chains/chain-2.png'
import chain20 from 'Assets/images/chains/chain-20.png'
import chain21 from 'Assets/images/chains/chain-21.png'
import chain22 from 'Assets/images/chains/chain-22.png'
import chain3 from 'Assets/images/chains/chain-3.png'
import chain4 from 'Assets/images/chains/chain-4.png'
import chain5 from 'Assets/images/chains/chain-5.png'
import chain6 from 'Assets/images/chains/chain-6.png'
import chain7 from 'Assets/images/chains/chain-7.png'
import chain8 from 'Assets/images/chains/chain-8.png'
import chain9 from 'Assets/images/chains/chain-9.png'
import AppLink from 'Component/AppLink'
import { HeadCenterbox, SSIconMBX01 } from './styled'
import HorizontalScroll from 'Component/core/elements/HorizontalScroll'

interface ISupportingBlockchainsProps {
  onClickTitle?: React.MouseEventHandler<HTMLButtonElement>
}

function SupportingBlockchains({ onClickTitle }: ISupportingBlockchainsProps) {
  return (
    <SSIconMBX01>

      <HeadCenterbox className='supported-chains'>
        <button className='title fw-bold' onClick={onClickTitle}>
          Blockchains Supported:
        </button>
        <div className='support-list'>
          <HorizontalScroll>
            <AppLink to='#'>
              <img src={chain1} alt='chain1' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain2} alt='chain2' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain3} alt='chain3' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain4} alt='chain4' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain5} alt='chain5' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain6} alt='chain6' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain7} alt='chain7' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain8} alt='chain8' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain9} alt='chain9' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain10} alt='chain10' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain11} alt='chain11' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain12} alt='chain12' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain13} alt='chain13' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain14} alt='chain14' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain15} alt='chain15' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain16} alt='chain16' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain17} alt='chain17' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain18} alt='chain18' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain19} alt='chain19' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain20} alt='chain20' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain21} alt='chain21' />
            </AppLink>
            <AppLink to='#'>
              <img src={chain22} alt='chain22' />
            </AppLink>
          </HorizontalScroll>
        </div>
      </HeadCenterbox>
    </SSIconMBX01>
  )
}

export default SupportingBlockchains
