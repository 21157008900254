import styled from 'styled-components'
import { FaEdit } from 'react-icons/fa'
import { AppButton, OutlineButton } from './Buttons'
import { FlexDiv } from './others/styled'
import Media from 'Theme/media-breackpoint'
import { GrHelp } from 'react-icons/gr'

export const SExampleList = styled.div`
  & > div {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }

  p {
    padding-left: 10px;
    margin-bottom: 0;
  }
`

export const SBondsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SBondsImg = styled.img`
  width: 70px;
`

export const SBondsContent = styled.p`
  max-width: 150px;
  text-align: center;
  font-family: var(--dx-ff-primary);
  font-size: ${({theme}) => theme.fontSize.sm};
  ${Media.sm} {
    max-width: unset;
  }
`

export const LaunchPoolContainer = styled.div`
  &.Wallet-Connected {
    .ConnectWallet {
      display: none !important;
    }
  }
  &.Wallet-Disconnected {
    .Bond_Tab_List {
      display: none !important;
    }
    .ConnectWallet {
      height: 30vh;
      margin-top: 70px;
      ${AppButton} {
        max-height: 59px
      }
    }
  }
`

export const SPairTokenButton = styled(OutlineButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.danger {
    border-color: ${({ theme }) => theme.colorRed};
    box-shadow: 0 0 7px 0px ${({ theme }) => theme.colorRed};
  }
  p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  span {
    padding-left: 10px;
    color: ${({ theme }) => theme.colorWhite};
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`

export const LaunchPoolInputGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > .label-section {
    overflow: hidden;
    white-space: nowrap;
    min-width: 300px;
    ${Media.lg} {
      min-width: 200px;
    }
    ${Media.md} {
      min-width: 150px;
    }
  }
  & > .input-section {
    width: 100%;
  }
`
// for bond terms tab
export const SForms = styled.table`
  & td {
    padding: 15px 0;
  }
  & td:nth-child(1) {
    min-width: 300px;
  }
  & td:nth-child(2) {
    min-width: 200px;
  }
  & td:nth-child(3) {
    min-width: 300px;
  }
  & td:nth-child(4) {
    min-width: 300px;
  }
  & td:nth-child(5) {
    min-width: 200px;
    width: 100%;
  }
  & tr.vertical-align-top {
    & > td {
      vertical-align: top;
    }
  }
`

// for fund raising terms tab
export const SForms1 = styled.table`
  width: 100%;

  & > tbody {
    width: 100%;
  }
  & > tbody > tr > td {
    vertical-align: top;
    padding: 0 0;
    width: 25%;
  }
  & > tbody > tr > td:nth-child(2) {
    padding-right: 30px;
  }
  & > tbody > tr > td:nth-child(3) {
    padding-left: 30px;
  }
`

export const BondTabTitle = ({ children }: { children: JSX.Element | any }) => {
  return <h3 className='text-primary fw-bold my-20'>{children}</h3>
}

export const BondSContentTable = styled.table`
  margin: 20px 0;
  width: 100%;

  & > tbody > tr > td {
    vertical-align: baseline;
    padding-top: 10px;
    padding-bottom: 10px;

    &:nth-child(odd) {
      width: 20%;
    }
    &:nth-child(even) {
      width: 30%;
      color: ${({ theme }) => theme.colorLightGrey};
    }
  }
  & > tbody > tr:last-child > td {
    padding-bottom: 25px;
  }
`
export const BondSelectPair = styled(FlexDiv)``

export const EditButton = styled(FaEdit)`
  color: white;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize['2md']};
  &:active {
    color: ${({ theme }) => theme.colorPrimary};
  }
`

export const SContentSubTable = styled.table`
  & > tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 5px;
    &:nth-child(1) {
      width: 40%;
    }

    &:nth-child(even) {
      // padding-left: 20px;
      color: ${({ theme }) => theme.colorLightGrey};
    }
  }
`
export const InfoItemLabel = styled.div`
  font-family: var(--dx-ff-primary);
  line-height: 16px;
`

interface IInfoItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: JSX.Element
  nobg?: boolean
  label: string | any
  helpText?: string
}

export function BondInfoItem({ icon, nobg = false, label, helpText, ...props }: IInfoItemProps) {
  return (
    <div className='d-flex my-3' {...props}>
      {/* <i className='fas fa-search'></i> */}
      {
        icon
          ? <div className={`d-flex rounded-circle justify-content-center align-items-center mr-8 ${nobg ? 'fs-15' : 'bg-white text-dark fs-12'}`} style={{ width: 15, height: 15 }}>
            {icon}
          </div>
          : null
      }
      <InfoItemLabel className='text-nowrap'>{label}</InfoItemLabel>
      {helpText && (
        <div className={'d-flex rounded-circle bg-white text-dark justify-content-center align-items-center -mt-2 ml-3'} style={{ width: 10, height: 10 }}>
          <GrHelp size={7} data-type='light' data-html='true' data-class='data-tooltip' data-tip={helpText} />
        </div>
      )}
    </div>
  )
}