import React, { useState } from 'react'
import { SupportedNetworks } from 'types';
import useAppDebounceEffect from './useAppDebounceEffect';
import { getPoolParams } from 'helper/sc-utils/ibo-sc-utils';


interface IUseIBOPoolParams {
    appChainId: SupportedNetworks
    classId: string
    nonceId: string
}

function useIBOPoolParams({ appChainId, classId, nonceId }: IUseIBOPoolParams) {
    const [poolParams, setPoolParams] = useState<{ totalRaisedPairTokens: string | number, spentTokens: string | number, fee: string | number }>({
        totalRaisedPairTokens: '',
        spentTokens: '',
        fee: ''
    });

    useAppDebounceEffect(() => {
        (async () => {
            try {
                const result = await getPoolParams(appChainId, classId, nonceId)
                setPoolParams({
                    fee: result.fee,
                    spentTokens: result.spentTokens,
                    totalRaisedPairTokens: result.totalRaisedPairTokens,
                })
            } catch (error) {
                console.log('UseIBOPoolParams Error: ', error)
                setPoolParams({
                    fee: 0,
                    spentTokens: 0,
                    totalRaisedPairTokens: 0,
                })
            }
        })()
    }, 300, [appChainId, classId, nonceId])

    return poolParams
}

export default useIBOPoolParams
