import React, { useMemo } from 'react'
import { AiFillWarning } from 'react-icons/ai'
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri'
import { numberFormater, usdFormater } from 'helper'
import * as S from './styled'
export * from './styled'
import { bigStringToShort } from 'helper/math'



interface IViewAddressProps extends React.HTMLAttributes<HTMLDivElement> {
  address: string
  start?: number
  end?: number
  as?: any
}

export function ViewAddress({ address, start = 5, end = 5, ...props }: IViewAddressProps) {
  const s = address.substring(0, start)
  const e = address.slice(-end)
  return (
    <S.ViewAddressWrap {...props}>
      {s}...{e}
    </S.ViewAddressWrap>
  )
}

interface IWrongNetworkBtnProps extends React.HTMLAttributes<HTMLDivElement> {
  iconSize?: string | number
  text: string | any
}

export function WrongNetworkBtn({ iconSize = 25, text, ...props }: IWrongNetworkBtnProps) {
  return (
    <S.FlexDiv {...props}>
      <S.SWrongNetworkBtn className='mr-5 justify-content-center'>
        <AiFillWarning size={iconSize} />
      </S.SWrongNetworkBtn>
      {text}
    </S.FlexDiv>
  )
}

export const AccordionToggleIcon = ({ open }: {
  open: boolean
}) => {
  return open ? <RiArrowUpSFill size={18} /> : <RiArrowDownSFill size={18} />
}

export const Completionist = () => <span>Ended!</span>

interface ITokenBalanceSpan extends React.HTMLAttributes<HTMLSpanElement> {
  children: number | string,
  digits?: number
  prefix?: string
  suffix?: string
}
export function TokenBalanceSpan({ children, digits = 4, prefix = '', suffix = '', ...props }: ITokenBalanceSpan) {
  const amount = +children
  // prefix exam: $ , USDT, BNB, ETH
  const balance = useMemo(() => {
    const balance = numberFormater(digits).format(amount);
    return bigStringToShort(balance + "");
  }, [children, digits])
  return (
    <span {...props}>
      {prefix}
      {balance}
      {suffix}
    </span>
  )
}

export function AmountLabel({ children, digits = 4 }: {
  children: number | string, digits?: number
}) {
  const amount = useMemo(() => {
    const n = +children
    return usdFormater(digits, digits).format(n)
  }, [children])
  return (
    <span>{amount}</span>
  )
}
