import styled from "styled-components";
import { FlexDiv } from "Component/core/elements/others/styled";
import Media from "Theme/media-breackpoint";
import { AppButton } from "Component/core/elements/Buttons";

const BallanceText = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: ${(props) => props.theme.colorWhite};
  &.desktop-div {
    ${Media.sm} {
      display: none;
    }
  }
  &.mobile-div {
    display: none;
    margin: 40px 0px 0px 35px;
    ${Media.sm} {
      display: block;
    }
  }
`;
const MenuIcon = styled.div`
  margin-left: 30px;
  margin-right: 20px;
  cursor: pointer;
  z-index: 1;
  :hover {
    .topdot {
      background-color: ${({ theme }) => theme.colorPrimary};
      animation: hoverpulse 2s infinite;
    }
    .middot {
      background-color: ${({ theme }) => theme.colorPrimary};
      animation: hoverpulse 2s infinite;
    }
    .bottomdot {
      background-color: ${({ theme }) => theme.colorPrimary};
      animation: hoverpulse 2s infinite;
    }
  }
  @keyframes hoverpulse {
    0% {
      box-shadow: 0 0 0 0 rgb(139 224 90 / 40%);
    }
    70% {
      box-shadow: 0 0 0 10px rgb(255 255 255 / 0%);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
    }
  }
`;
const TopDot = styled.div`
  width: 8px;
  height: 8px;
  margin: 4px;
  background: white;
  border-radius: 50%;
  &.topdot {
    background: white;
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(255 255 255 / 40%);
    }
    70% {
      box-shadow: 0 0 0 10px rgb(255 255 255 / 0%);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
    }
  }
`;
const MidDot = styled.div`
  width: 6px;
  height: 6px;
  margin: 5px;
  background: white;
  border-radius: 50%;
  &.middot {
    background: white;
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(255 255 255 / 40%);
    }
    70% {
      box-shadow: 0 0 0 10px rgb(255 255 255 / 0%);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
    }
  }
`;
const BottomDot = styled.div`
  width: 4px;
  height: 4px;
  margin: 4px 6px 4px 6px;
  border-radius: 50%;
  &.bottomdot {
    background: white;
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(255 255 255 / 40%);
    }
    70% {
      box-shadow: 0 0 0 10px rgb(255 255 255 / 0%);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
    }
  }
`;
const Logo = styled.a`
  overflow: hidden;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 20px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  img {
    object-fit: none;
    height: auto;
    // min-width: 100%;
    min-height: 70px; // 70px is height of logo
    object-position: left;
  }
`;

const HeadRightbox = styled(FlexDiv)`
  flex-wrap: initial;
  a {
    &.active {
      color: ${({ theme }) => theme.colorPrimary};
    }
  }
  .img-outer {
    width: 22px;
    height: 22px;
    margin-right: 20px;
    img {
      width: 100%;
      min-width: 22px; // need this property
      height: 100%;
      object-fit: contain;
    }
    &.desktop-div {
      ${Media.sm} {
        display: none;
      }
    }
  }
`;

const WalletAddressItem = styled.p`
  color: white;
`;

const WalletAddress = styled.div`
  color: ${(props) => props.theme.colorWhite};
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;
type HeadCenterboxV2Props = {
  navAlign: "center" | "left";
};
const HeadCenterboxV2 = styled.div<HeadCenterboxV2Props>`
  margin: 0 auto;
  display: flex;
  flex: 1;
  font: normal 11px/20px "Press Start 2P", arial;
  color: #fff;
  ${(props) => {
    if (props.navAlign == "center") {
      return {
        justifyContent: "center",
        paddingRight: "20px",
        width: "100%",
      };
    }
    return {
      width: "auto",
      paddingLeft: "20px",
      justifyContent: "left",
    };
  }}

  a {
    color: ${(props) => props.theme.colorLightGrey};
    padding: 6px 12px 6px 20px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    font-family: var(--dx-ff-primary);
    text-align: center;
    &.inactive {
      color: #aba5a5;
    }
    &:hover {
      color: #fff;
    }
    &.active {
      color: ${({ theme }) => theme.colorPrimary};
    }
  }

  ${Media.md2} {
    width: 42%;
    a {
      white-space: nowrap;
    }
  }
  ${Media.sm} {
    display: none;
  }
`;

type MainheadboxProps = {
  bg?: string;
  top?: string;
};
const Mainheadbox = styled.div<MainheadboxProps>`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  min-height: 85px;
  position: fixed;
  left: 0;
  right: 0;
  top: ${(props) => (props.top ? props.top : "40px")};
  padding: 0 15px;
  z-index: 30; // changed to 1 from 100 because of appsnackvar
  background-color: ${(props) => props.bg || props.theme.headbg02};

  ${HeadRightbox} > .dropdown {
    display: none;
    & .dropdown-menu > li {
      display: none;
    }
  }

  ${Media.lg} {
    ${HeadCenterboxV2} > .header-app-link:nth-last-child(-n + 4) {
      display: none;
    }
    ${HeadRightbox} > .dropdown {
      display: block;

      & .dropdown-menu > li:nth-last-child(-n + 4) {
        display: block;
      }
    }
  }
  ${Media.lg2} {
    ${HeadCenterboxV2} > .header-app-link:nth-last-child(-n + 5) {
      display: none;
    }
    ${HeadRightbox} > .dropdown .dropdown-menu > li:nth-last-child(-n + 5) {
      display: block !important;
    }
  }
  ${Media.md} {
    ${HeadCenterboxV2} > .header-app-link {
      display: none;
    }
    ${HeadRightbox} > .dropdown .dropdown-menu > li {
      display: block !important;
    }
  }

  ${Media.xs} {
    align-items: center;
  }
`;
const MenuRight = styled.div`
  .collapse-css-transition {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
    box-shadow: -10px 0px 10px 0px ${(props) => props.theme.boxShadowOne};
  }
`;

const MenuBg = styled.div`
  width: 326px;
  height: 100vh !important;
  background-color: ${(props) => props.theme.bodybg011};
  hr {
    border-color: ${(props) => props.theme.bodybg01};
    margin: 0px 35px 25px;
  }
  img.logo {
    padding: 0px 0px 0px 35px;
  }
`;

const MenuTop = styled(FlexDiv)`
  flex-wrap: initial;
  justify-content: space-between;
  padding: 25px 35px;
  ${Media.sm} {
    padding: 20px 35px 40px;
  }
  .m-inner {
    display: flex;
    align-items: center;
    justify-contnet: center;
    .img-outer {
      margin-right: 10px;
      display: none;
      ${Media.sm} {
        display: block;
      }
    }
  }
`;

const MenuLinks = styled.div`
  padding: 0px 35px;
  a {
    font-size: 16px;
    color: ${(props) => props.theme.colorWhite};
    font-family: var(--dx-ff-secondary);
    display: block;
    margin-bottom: 25px;
    font-weight: 700;
    :hover,
    &.active {
      color: ${(props) => props.theme.colorPrimary};
    }
  }
`;

const MenuLinks2 = styled.div`
  margin-left: 20px;
  a {
    font-size: 12px;
    color: ${(props) => props.theme.colorLightGrey};
    font-family: var(--dx-ff-secondary);
    display: block;
    margin-bottom: 25px;
    :hover,
    &.active {
      color: ${(props) => props.theme.colorGrey};
    }
  }
`;

const CustomAccordion = styled.div`
  padding: 0px 35px;
  .accordion {
    .accordion__button {
      font-size: 12px;
      color: ${(props) => props.theme.colorWhite};
      font-family: "Press Start 2P", cursive;
      cursor: pointer;
      margin-bottom: 25px;
      :hover {
        color: ${(props) => props.theme.colorPrimary};
      }
    }
    .accordion__panel {
      padding: 0px;
    }
  }
`;

const CustomAccordion2 = styled.div`
  margin-bottom: 30px;
  padding: 0px 35px;
  .accordion {
    .accordion__item {
      background-color: ${(props) => props.theme.bodybg012};
      padding: 15px 12px;
      border-radius: 5px;
    }
    .accordion__button {
      font-size: 14px;
      font-weight: bold;
      color: ${(props) => props.theme.colorWhite};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        font-size: 12px;
      }
    }
  }
`;

const VersionLink = styled.div`
  a {
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.colorGrey5};
    display: block;
    padding: 10px 0px;
    &.active {
      color: ${(props) => props.theme.colorWhite};
    }
    :last-child {
      padding-bottom: 0px;
    }
    :first-child {
      padding-top: 20px;
    }
  }
`;

const CustomAccordion3 = styled.div`
  padding: 0px 35px;
  .accordion {
    .accordion__button {
      font-size: 14px;
      font-weight: bold;
      color: ${(props) => props.theme.colorWhite};
      cursor: pointer;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        font-size: 12px;
      }
    }
  }
`;

const LibLinks = styled.div`
  a {
    font-size: 12px;
    color: ${(props) => props.theme.colorLightGrey};
    display: block;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    :hover {
      color: ${(props) => props.theme.colorGrey};
    }
    svg {
      font-size: 16px;
      margin-right: 10px;
    }
  }
`;

const ThemeSection = styled.div`
  margin: 30px 0px;
  padding: 0px 35px;
  svg {
    font-size: 20px;
    margin-right: 25px;
    color: ${(props) => props.theme.colorLightGrey};
    cursor: pointer;
    &.active {
      color: ${(props) => props.theme.colorPrimary};
    }
  }
`;

const CopyRText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colorLightGrey};
  letter-spacing: 0px;
  margin: 20px 0px 15px;
  padding: 0px 35px;
  font-family: var(--dx-ff-primary);
  &.mt-15 {
    margin-top: 15px;
  }
`;

const PoweredBy = styled(FlexDiv)`
  flex-wrap: initial;
  justify-content: flex-start;
  padding: 0px 35px;
  p {
    margin: 0px 15px 0px 0px;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    color: ${(props) => props.theme.colorLightGrey};
  }
`;
const LPDropDownGroup = styled.div`
  direction: rtl;
`;
const LPDropDownDiv = styled.div`
  margin: 15px 0;
  text-align: left;
  position: relative;
  & img {
    width: 200px;
    transition: filter 0.3s ease; /* Add transition for smooth effect */
  }
  &:hover img {
    opacity: 0.7; /* Set the opacity to 0.7 when hovered */
  }

  & span {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-self: center;
    position: absolute; /* Position the text absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the text precisely */
    background-color: transparent;
    color: white;
    padding: 20px;
    font-size: 24px; /* Adjust font size as needed */
    font-weight: bold;
    border-radius: 5px;
    font-color: white;
    opacity: 0; /* Initially hidden */ /* Add transition for smooth effect */
  }
  &:hover span {
    opacity: 1; /* Set the opacity to 1 when hovered */
  }
`;

const S = {
  BallanceText,
  PoweredBy,
  CopyRText,
  ThemeSection,
  LibLinks,
  CustomAccordion3,
  VersionLink,
  CustomAccordion2,
  CustomAccordion,
  MenuLinks2,
  MenuLinks,
  MenuTop,
  MenuBg,
  MenuRight,
  Mainheadbox,
  HeadCenterboxV2,
  ConnectWalletButton: AppButton,
  WalletAddress,
  WalletAddressItem,
  HeadRightbox,
  Logo,
  BottomDot,
  MidDot,
  TopDot,
  MenuIcon,
  LPDropDownDiv,
  LPDropDownGroup,
};

export default S;
