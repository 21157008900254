import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Modal from "../Modal";
import useWallet from "hooks/useWallet";
import Web3 from "web3";
import CWIco01 from "../../Assets/images/cwICON-01.png";
import CWIco02 from "../../Assets/images/cwICON-02.png";
import CWIco03 from "../../Assets/images/cwICON-03.png";
import CWIco04 from "../../Assets/images/cwICON-04.png";

import { PPClosBTN01 } from "Component/core/elements/Buttons";
import {
  FlexDiv,
  ModalBox,
} from "Component/core/elements/others/global-components";
import { LOCAL_STORAGE_ITEM_NAMES } from "app-constants";
import { GlobalsSelector } from "redux/selectors";
import { toggleConnectWalletModal } from "redux/reducers/globals/globalActions";
import { WalletConnectorTypes, WalletInstallStatus } from "types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { WalletOfficialSites } from "Connectors";
import AppTooltip, { AppToolTipControl } from "Component/AppTooltip";
import { isDesktop, isMobile } from "react-device-detect";

function ConnectWallet() {
  const dispatch = useAppDispatch();
  const { showConnectWalletModal: isOpen } = useAppSelector(GlobalsSelector);
  const { active, connectWallet } = useWallet();

  const walletInstallStatus = useMemo<WalletInstallStatus>(() => {
    const isEthereumAvailable = typeof window.ethereum !== "undefined";
    const isGateWalletAvailable = typeof window.gatewallet !== "undefined";
    const isTrustWalletAvailable = typeof window.trustwallet !== "undefined";

    return {
      [WalletConnectorTypes.METAMASK]:
        isEthereumAvailable && window.ethereum.isMetaMask,
      [WalletConnectorTypes.TRUST_WALLET]:
        isTrustWalletAvailable ||
        (isEthereumAvailable && window.ethereum.isTrust),
      [WalletConnectorTypes.GATE_WALLET]: isGateWalletAvailable,
    };
  }, []);

  const dismiss = useCallback(() => {
    dispatch(toggleConnectWalletModal(false));
    AppToolTipControl.hide();
  }, []);

  const handleOnClickConnectWalletButton = useCallback(
    async (type: WalletConnectorTypes) => {
      if (isDesktop && !walletInstallStatus[type]) {
        return window.open(WalletOfficialSites[type], "_blank");
      }

      if (isMobile) {
        const mobileUrls = {
          [WalletConnectorTypes.METAMASK]: `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`,
          [WalletConnectorTypes.TRUST_WALLET]: `https://link.trustwallet.com/open_url?coin_id=60&url=${encodeURIComponent(
            window.location.href
          )}`,
          [WalletConnectorTypes.GATE_WALLET]: `gatewallet://dapp?url=${encodeURIComponent(
            window.location.href
          )}`,
        };

        if (mobileUrls[type] && !walletInstallStatus[type]) {
          window.location.href = mobileUrls[type];
          return;
        }
      }

      connectWallet(type);
    },
    [connectWallet, isDesktop, isMobile, walletInstallStatus]
  );

  useEffect(() => {
    if (active) {
      dismiss();
    }
  }, [active, dismiss]);

  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx>
        <PPsBx01 className="v2">
          <PPstitle01>
            Connect wallet
            <PPClosBTN01 className="right-0 top-0" onClick={dismiss} />
          </PPstitle01>
        </PPsBx01>

        <CWBTNBX>
          {[
            {
              type: WalletConnectorTypes.METAMASK,
              name: "MetaMask",
              icon: CWIco02,
            },
            {
              type: WalletConnectorTypes.TRUST_WALLET,
              name: "TrustWallet App",
              icon: CWIco03,
            },
            {
              type: WalletConnectorTypes.GATE_WALLET,
              name: "Gate Wallet",
              icon: CWIco04,
            },
          ].map(({ type, name, icon }) => (
            <button
              key={type}
              onClick={() => handleOnClickConnectWalletButton(type)}
            >
              {isMobile
                ? "Connect"
                : walletInstallStatus[type]
                ? "Connect"
                : type === WalletConnectorTypes.TRUST_WALLET
                ? "Download"
                : "Install"}{" "}
              {name}
              <i>
                <img src={icon} alt="" />
              </i>
            </button>
          ))}
        </CWBTNBX>

        <PPsBx02 className="v2">
          <button>
            {/*Having trouble finding a token?*/} Learn more about wallets
          </button>
        </PPsBx02>
      </PPMainBx>

      <AppTooltip />
    </Modal>
  );
}

const PPMainBx = styled(ModalBox)`
  max-width: 431px;
  margin: 0 auto;
`;

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
  border-bottom: #3c3f46 1px solid;

  &.v2 {
    border-bottom: 0px;
  }
`;

const PPsBx02 = styled(FlexDiv)`
  width: 100%;
  padding: 20px 30px 20px 30px;
  border-top: #3c3f46 1px solid;

  &.v2 {
    font-size: 12px;
    border-top: 0px;
    button {
      color: #8be05a;
    }
  }

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;
    :hover {
      opacity: 0.7;
    }
  }
`;

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
`;

const CWBTNBX = styled(FlexDiv)`
  width: 100%;
  padding: 10px 30px 0px 30px;

  button {
    width: 100%;
    height: 62px;
    border: 1px solid #545861;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    padding: 10px 14px;
    color: #ffffff;
    position: relative;
    margin: 0 0 15px 0;

    i {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 11px;
      top: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :hover {
      background-color: #32353b;
    }
  }
`;

export default ConnectWallet;
