import { appThemeConfigs } from "config/app-config";
import { GlobalReducerState, SupportedNetworks } from "types";
import { ActionGlobals, ActionTypes } from "./types";

const initialState: GlobalReducerState = {
  showConnectWalletModal: false,
  processingModal: {
    isOpen: false,
    message: "",
  },
  availableTokens: [],
  failedT_ModalStatus: {
    // failed transaction modal status
    isOpen: false,
    message: "",
    header: "",
  },
  transactionModal: {
    isOpen: false,
    hash: "",
  },
  appErrorModal: {
    isOpen: false,
    message: "",
  },
  account: "",
  themeConfig: appThemeConfigs.default,
  userTokensWithBalance: {
    isLoading: true,
    errorMessage: "",
    tokens: [],
  },
  appChainId: SupportedNetworks.bsc, // 97 BSC test network chain id
};

export default function globalReducer(
  state = initialState,
  action: ActionGlobals
): GlobalReducerState {
  switch (action.type) {
    case ActionTypes.toggleConnectWalletModal:
      if (typeof action.payload === "undefined") {
        return {
          ...state,
          showConnectWalletModal: !state.showConnectWalletModal,
        };
      }
      return { ...state, showConnectWalletModal: action.payload };

    case ActionTypes.setAvailableTokens:
      return { ...state, availableTokens: action.payload };

    case ActionTypes.SET_IS_OPEN_TRANSACTION_MODAL:
      return { ...state, processingModal: action.payload };

    case ActionTypes.setOpenTransationFailedModal:
      return { ...state, failedT_ModalStatus: action.payload };

    case ActionTypes.setTransactionModal:
      return { ...state, transactionModal: action.payload };

    case ActionTypes.setAppErrorModal:
      return { ...state, appErrorModal: action.payload };

    case ActionTypes.SET_ACCOUNT:
      return { ...state, account: action.payload };

    case ActionTypes.SET_CHAIN:
      return { ...state, appChainId: action.payload };

    case ActionTypes.SET_APP_THEME:
      return { ...state, themeConfig: action.payload };

    case ActionTypes.SET_USER_TOKENS_WITH_BALANCE:
      return { ...state, userTokensWithBalance: action.payload };
    default:
      return state;
  }
}
