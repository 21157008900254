import Web3 from "web3";
import { TransactionReceipt } from "web3-core";
import { AbiItem } from "web3-utils";
import BSwapPairAbi from "Assets/Abi/BSwapPairAbi.json";
import { ZERO_ADDRESS } from "app-constants";

class BSwapPairHelper {
  web3;
  constructor(_web3: Web3) {
    this.web3 = _web3;
  }

  balanceOf = async (lpAddress: string, account: string) => {
    try {
      const lpInstance = new this.web3.eth.Contract(
        BSwapPairAbi as AbiItem[],
        lpAddress
      );
      const balance = await lpInstance.methods.balanceOf(account).call();
      return balance;
    } catch (err) {
      return 0;
    }
  };

  baseLineLP = async (lpAddress: string) => {
    try {
      const lpInstance = new this.web3.eth.Contract(
        BSwapPairAbi as AbiItem[],
        lpAddress
      );
      const balance = await lpInstance.methods.baseLineLP().call();
      return balance;
    } catch (err) {
      return 0;
    }
  };

  floorPrice0 = async (lpAddress: string) => {
    try {
      const lpInstance = new this.web3.eth.Contract(
        BSwapPairAbi as AbiItem[],
        lpAddress
      );
      const result = await lpInstance.methods.floorPrice0().call();
      return result;
    } catch (err) {
      return { floorPrice0: 0, baseToken: "" };
    }
  };

  getFloorPrice = async (lpAddress: string, baseToken: string) => {
    try {
      const lpInstance = new this.web3.eth.Contract(
        BSwapPairAbi as AbiItem[],
        lpAddress
      );
      const result = await lpInstance.methods.getFloorPrice(baseToken).call();
      return result;
    } catch (err) {
      return { floorPrice: 0, currentBaseToken: ZERO_ADDRESS };
    }
  };
  getTaxToken = async (lpAddress: string) => {
    const lpInstance = new this.web3.eth.Contract(
      BSwapPairAbi as AbiItem[],
      lpAddress
    );
    const result = await lpInstance.methods.taxToken().call();
    return {
      token: result.token,
      buyTaxReceiver: result.buyTaxReceiver,
      buyTax: result.buyTax / 1e2, // remove two decimals
      sellTaxReceiver: result.sellTaxReceiver,
      sellTax: result.sellTax / 1e2,
    };
  };
  isPrivate = async (lpAddress: string): Promise<boolean> => {
    const lpInstance = new this.web3.eth.Contract(
      BSwapPairAbi as AbiItem[],
      lpAddress
    );
    return await lpInstance.methods.isPrivate().call();
  };
  /**
   * @description web3 write func
   */
  switchToPublic = async (
    lpAddress: string,
    account: string
  ): Promise<TransactionReceipt> => {
    const lpInstance = new this.web3.eth.Contract(
      BSwapPairAbi as AbiItem[],
      lpAddress
    );
    const method = lpInstance.methods.switchToPublic();
    await method.estimateGas({ from: account }); // to check params
    return await method.send({ from: account });
  };
}

export default BSwapPairHelper;
