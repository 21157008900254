import styled from "styled-components";
import { FlexDiv } from "Component/core/elements/others/styled";
import { AppButton } from "Component/core/elements/Buttons";
import { Divider } from "Component/core/elements/Divider";

export const PageBox = styled.div`
  background-color: ${({ theme }) => theme.pageBgColor};
  padding: 50px;
`

export const DetailStatusDiv = styled(FlexDiv)`
  span.pool-id {
    font-size: ${({ theme }) => theme.fontSize.md};
    font-family: var(--dx-ff-primary);
    font-weight: bold;
    text-transform: uppercase;
  }
`

export const DetailTitleSpan = styled.span`
  font-weight: 400;
  font-family: var(--dx-ff-primary);
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colorLightGrey};
`

export const ReviewButton = styled(AppButton)`
  padding: 3px 10px;
  border-radius: 10px;
  border-start-start-radius: 0;
  border-end-end-radius: 0;
  background-color: ${({ theme }) => theme.placeHolderColor};
  color: ${({ theme }) => theme.colorLightGrey};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: 400;
  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
`

export const ProjectDetailBox = styled(FlexDiv)`
  justify-content: space-between;
  color: white;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  .ProjectDetailBox-Info {
    display: flex;
    margin-top: 2px;
    padding-top: 5px;
    width: 60%;
    &-Details {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .ProjectDetailBox-Detail {
    width: calc(40% - 1px);
    padding-left: 15px;
  }
  ${Divider} {
    height: 40px !important;
  }
`

export const ProjectName = styled.a`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSize['2md']};
`

export const ProjectSymbol = styled.h6`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize['2xl']};
`

export const ProjectDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  .title {
    color: ${({ theme }) => theme.colorLightGrey};
  }
  .detail {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colorPrimary};
  }
`

export const SocialCategoryTitle = styled.h2`
  font-weight: 800;
`

export const TweetButton = styled.a`
  width: 100%;
  text-align: left;
  border-radius: 5px;
  padding: 5px;
  margin-top: 15px;
  font-size: ${({theme}) => theme.fontSize.sm};
  font-family: var(--dx-ff-primary);
  color: ${({ theme }) => theme.colorLightGrey};
  background-color: ${({ theme }) => theme.colorLightGrey01};
  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
`

export const LPInsurePoolDetailBox = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;

  & > div:first-child {
    padding-right: 20px;
  }
  & > div:not(:first-child) {
    padding: 0 20px;
  }
  div.Bond-Twitter-Section {
    width: 20%;
  }
  div.Bond-Telegram-Section {
    width: 20%;
  }
  div.Bond-News-Section {
    width: 20%;
  }
  .SocialCategory-Icon{
    color: black;
  }
`

export const GraphAndDetail = styled.div`
`