import Web3 from "web3";
import { TransactionReceipt } from "web3-core";
import { isAddress, AbiItem } from "web3-utils";
import fromExponential from "from-exponential";

import ERC20Abi from "Assets/Abi/ERC20.json";
import LPInsureProxyAbi from "Assets/Abi/lp-insure-proxy-abi.json";
import DumperShieldABI from "Assets/Abi/DumperShieldABI.json";
import { ActionLPInsure, ActionTypes } from "./types";
import Web3Helpers from "helper/Web3Helpers";
import {
  getOfferTime,
  getTimeInSecs,
  isEqAddr,
  removeEmptyFieldsInObject,
} from "helper";
import { RootState } from "redux/store";
import {
  LPInsureReducerParams,
  TokenParamsWithBalance,
  AppThunkAction,
  VestingPeriodParams,
  InvestmentLimitParams,
  DexInfo,
  LPInsureTabs,
  Parameters,
  SupportedNetworks,
  PoolParamsReturnType,
  PoolParametersReturnType,
  BaseToken,
} from "types";
import { LP_INSURE_PROXY_FACTORY } from "app-constants/lp-insure";
import {
  BSwaprouter2ContractAddress,
  NETWORKS,
  ZERO_ADDRESS,
} from "app-constants";
import {
  decimalAmountToExactAmount,
  removeDecimalsInBigString,
  simpleAmountInString,
} from "helper/math";
import { createDumperShield } from "helper/sc-utils/DS-sc-utils";
import Decimal from "decimal.js";

type LPInsureAppThunkAction<
  R, // Return type of the thunk function
  E // any "extra argument" injected into the thunk
> = AppThunkAction<R, RootState, E, ActionLPInsure>;

const loadTokenInfo =
  (_token: string): LPInsureAppThunkAction<void, null> =>
  async (dispatch, getState) => {
    const account = getState().globals.account;

    try {
      const web3 = new Web3(Web3.givenProvider);
      const w3Helper = new Web3Helpers(web3, account);
      const tokenContract = new web3.eth.Contract(
        ERC20Abi as AbiItem[],
        _token
      );
      const [_symbol, _name, _decimals, _balance] = await Promise.all([
        tokenContract.methods.symbol().call(),
        tokenContract.methods.name().call(),
        tokenContract.methods.decimals().call(),
        w3Helper.getTokenBalance(_token),
      ]);

      dispatch({
        type: ActionTypes.SET_TOKEN_INFO,
        payload: {
          name: _name,
          symbol: _symbol,
          address: _token,
          decimals: _decimals,
          balance: fromExponential(+_balance / 10 ** _decimals),
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_TOKEN_INFO,
        payload: {
          name: "",
          symbol: "",
          address: "",
          decimals: "",
          balance: "0",
        },
      });
    }
  };

export const loadTokenRatio =
  (): LPInsureAppThunkAction<Promise<any>, null> =>
  async (dispatch, getState) => {
    const { amountOfSupply, raisingTarget } = getState().bond;

    dispatch({
      type: ActionTypes.SET_TOKEN_RATIO,
      payload: +raisingTarget / +amountOfSupply,
    });
  };

export const setLPInsureDex =
  (values: DexInfo): LPInsureAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_DEX,
      payload: values,
    });
  };

export const setParams =
  (values: LPInsureReducerParams): LPInsureAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_PARAMETERS,
      payload: values,
    });
  };

export const setPrepaymentPenalty =
  (enable = true): LPInsureAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_PREPAYMENT_PENALTY,
      payload: enable,
    });
  };

export const setParamsVestingPeriod =
  (values: VestingPeriodParams): LPInsureAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_VESTING_PERIOD,
      payload: values,
    });
  };

export const setParamsInvestmentLimit =
  (values: InvestmentLimitParams): LPInsureAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INVESTMENT_LIMIT,
      payload: values,
    });
  };

export const nextStep =
  (): LPInsureAppThunkAction<void, null> => (dispatch, getState) => {
    const tab = getState().lpInsure.tab;

    dispatch({
      type: ActionTypes.SET_TAB,
      payload: tab + 1,
    });
  };

export const prevStep =
  (): LPInsureAppThunkAction<void, null> => (dispatch, getState) => {
    const tab = getState().lpInsure.tab;

    dispatch({
      type: ActionTypes.SET_TAB,
      payload: tab - 1,
    });
  };

export const setStep =
  (tabIndex: LPInsureTabs): LPInsureAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TAB,
      payload: tabIndex,
    });
  };
export const setInvestmentLimitState =
  (state: boolean): LPInsureAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INVESTMENT_LIMIT_ENABLE,
      payload: state,
    });
  };

export const setPairToken =
  ({
    name,
    symbol,
    address,
    decimals,
  }: {
    name: string;
    symbol: string;
    address: string;
    decimals: string;
  }): LPInsureAppThunkAction<Promise<void>, null> =>
  async (dispatch) => {
    const newPairToken = removeEmptyFieldsInObject({
      name,
      symbol,
      address,
      decimals,
    });

    dispatch({
      type: ActionTypes.SET_PAIR_TOKEN,
      payload: newPairToken as TokenParamsWithBalance,
    });
  };

export const setToken =
  (_token: string): LPInsureAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TOKEN,
      payload: _token,
    });
    if (isAddress(_token)) {
      dispatch(loadTokenInfo(_token));
    }
  };

export const createLpInsureOffer =
  (): LPInsureAppThunkAction<Promise<TransactionReceipt>, null> =>
  async (dispatch, getState) => {
    const { appChainId, account } = getState().globals;
    const {
      token,
      startIBODate,
      endIBODate,
      investmentLimit,
      dynamicPenalty,
      amountOfSupply,
      pairToken,
      dex,
      vestingPeriod,
      tokenInfo,
    } = getState().lpInsure;

    const supplyAmount = simpleAmountInString(
      +amountOfSupply,
      +tokenInfo.decimals
    );
    const startDate = getTimeInSecs(getOfferTime(startIBODate, 10));
    const endDate = getTimeInSecs(getOfferTime(endIBODate, 2));
    const minInvestment = simpleAmountInString(
      +investmentLimit.minimum,
      +tokenInfo.decimals
    );
    const maxInvestment = simpleAmountInString(
      +investmentLimit.maximum,
      +tokenInfo.decimals
    );

    const network = NETWORKS[appChainId];
    const web3 = new Web3(Web3.givenProvider);
    const lpInsureInstance = new web3.eth.Contract(
      LPInsureProxyAbi as AbiItem[],
      LP_INSURE_PROXY_FACTORY[appChainId]
    );
    const isCoin =
      isEqAddr(pairToken.address, network.W_TOKEN_ADDRESS) ||
      isEqAddr(pairToken.address, ZERO_ADDRESS);
    const params: Parameters = {
      startDate: startDate,
      endDate: endDate,
      isCoin: isCoin,
      minInvestment: minInvestment,
      maxInvestment: maxInvestment,
      pairToken: pairToken.address,
      router: dex.router,
      supplyAmount: supplyAmount,
      token: token,
      vestingParams: {
        cliffDays: vestingPeriod.afterOption.value * +vestingPeriod.afterValue, // todo need to research this part
        prepaymentPenalty: +dynamicPenalty * 1e2,
      },
    };
    let value: undefined | string = undefined;
    const method = lpInsureInstance.methods.createOffer(params);
    try {
      // To check params
      await method.estimateGas({ from: account, value: value });
      if (isCoin) {
        value = supplyAmount;
      }
      const gasPrice: string = await web3.eth.getGasPrice();
      const result = await method.send({
        from: account,
        value: value,
        gasPrice: removeDecimalsInBigString(+gasPrice * 1.1),
      });
      dispatch(nextStep());
      return result;
    } catch (error: unknown) {
      // @ts-ignore
      const message: string = error.message || error.toString();
      if (message.search(/Pair not exist/gi) > -1) {
        throw new Error(
          "You can not create with this token. because no pool exists"
        );
      }
      if (message.search(/wrong WETH address/gi) > -1) {
        throw new Error(
          "wrong Main Token address. Contact them to add main token address"
        );
      }
      throw error;
    }
  };

export const getPoolParams = async (
  offerId: string | number,
  appChainId: SupportedNetworks
): Promise<PoolParamsReturnType> => {
  const web3 = new Web3(Web3.givenProvider);
  const lpInsureInstance = new web3.eth.Contract(
    LPInsureProxyAbi as AbiItem[],
    LP_INSURE_PROXY_FACTORY[appChainId]
  );
  const result = await lpInsureInstance.methods.poolParams(offerId).call();
  return result;
};

export const getLpInsureParameters = async (
  offerId: string | number,
  appChainId: SupportedNetworks
): Promise<PoolParametersReturnType> => {
  const web3 = new Web3(Web3.givenProvider);
  const lpInsureInstance = new web3.eth.Contract(
    LPInsureProxyAbi as AbiItem[],
    LP_INSURE_PROXY_FACTORY[appChainId]
  );
  const result = await lpInsureInstance.methods.parameters(offerId).call();
  return result;
};

export const buyLpInsureOffer =
  (
    tokenAddr: string,
    tDecimals: string | number,
    offerId: string | number,
    amount: string | number,
    W_TOKEN_ADDRESS: string
  ): LPInsureAppThunkAction<Promise<void>, null> =>
  async (dispatch, getState) => {
    const { appChainId, account } = getState().globals;
    const web3 = new Web3(Web3.givenProvider);
    const insure = LP_INSURE_PROXY_FACTORY[appChainId];
    const decimalsAmount = simpleAmountInString(amount, tDecimals);
    let value = "";
    if (isEqAddr(tokenAddr, W_TOKEN_ADDRESS)) {
      value = decimalsAmount;
    } else {
      const w3Helper = new Web3Helpers(web3, account);
      await w3Helper.smartApproveToken(
        tokenAddr,
        tDecimals,
        simpleAmountInString(amount, +tDecimals),
        insure,
        account,
        W_TOKEN_ADDRESS
      );
    }
    const lpInsureInstance = new web3.eth.Contract(
      LPInsureProxyAbi as AbiItem[],
      LP_INSURE_PROXY_FACTORY[appChainId]
    );
    const method = lpInsureInstance.methods.buyOffer(offerId, decimalsAmount);

    try {
      await method.estimateGas({ from: account, value });
      const result = await method.send({ from: account, value });
      return result;
    } catch (error: unknown) {
      // @ts-ignore
      const errMsg: string = error.message || String(error);
      throw new Error(errMsg);
    }
  };

export const withdrawProjectTokens =
  (
    offerId: number | string,
    decimalsAmount: string
  ): LPInsureAppThunkAction<Promise<void>, null> =>
  async (dispatch, getState) => {
    const { appChainId, account } = getState().globals;
    const web3 = new Web3(Web3.givenProvider);
    const lpInsureInstance = new web3.eth.Contract(
      LPInsureProxyAbi as AbiItem[],
      LP_INSURE_PROXY_FACTORY[appChainId]
    );
    const method = lpInsureInstance.methods.withdrawProjectTokens(
      offerId,
      decimalsAmount
    );
    const gas = await method.estimateGas({ from: account });
    const result = await method.send({ from: account, gas });
    return result;
  };

export const addFarm =
  (
    classId: string,
    token: BaseToken,
    amount: string,
    period: number,
    toDumperShield: boolean,
    unlockDate: undefined | number = undefined
  ): LPInsureAppThunkAction<Promise<TransactionReceipt>, null> =>
  async (dispatch, getState) => {
    const { account, appChainId } = getState().globals;
    const { W_TOKEN_ADDRESS } = NETWORKS[appChainId];
    let mainTokenValue: string | undefined = undefined;
    let isCoin = false;
    if (isEqAddr(token.address, W_TOKEN_ADDRESS)) {
      mainTokenValue = fromExponential(amount);
      isCoin = true;
    }
    const insureOfferContract = LP_INSURE_PROXY_FACTORY[appChainId];
    const w3 = new Web3(Web3.givenProvider);
    if (!isCoin) {
      const w3Helpers = new Web3Helpers(w3, account);
      const exactAmount = decimalAmountToExactAmount(amount, token.decimals);
      await w3Helpers.smartApproveToken(
        token.address,
        token.decimals,
        simpleAmountInString(exactAmount, token.decimals),
        insureOfferContract,
        account,
        W_TOKEN_ADDRESS
      );
    }
    const bondInstance = new w3.eth.Contract(
      LPInsureProxyAbi as AbiItem[],
      insureOfferContract
    );

    if (toDumperShield) {
      const DumperShieldContract = await bondInstance.methods
        .dumperShield()
        .call();
      const DSInstance = new w3.eth.Contract(
        DumperShieldABI as AbiItem[],
        DumperShieldContract
      );
      const shieldToken = await DSInstance.methods
        .dumperShieldTokens(token.address)
        .call();
      if (isEqAddr(shieldToken, ZERO_ADDRESS)) {
        await createDumperShield(
          appChainId,
          account,
          token.address,
          BSwaprouter2ContractAddress[appChainId],
          unlockDate as number
        );
      } else {
        console.log("dumperShieldTokens exists. continue addFarm...");
      }
    }
    const method = bondInstance.methods.addFarm(
      classId,
      token.address,
      amount,
      period,
      toDumperShield
    );
    try {
      await method.estimateGas({ from: account, value: mainTokenValue });
      const result = await method.send({
        from: account,
        value: mainTokenValue,
      });
      return result as TransactionReceipt;
    } catch (error: unknown) {
      // @ts-ignore
      const errorMessage: string = error.message || error.toString();
      console.log(error);
      if (errorMessage.search(/dumperShield error/gi) > -1) {
        throw new Error(
          "There is no token in Dumper Shield Token list. \n Try with another token or contact support to add your reward token."
        );
      }
      if (errorMessage.search(/max Farms added/gi) > -1) {
        throw new Error("Max Farms Added!.You can't add any more");
      }
      if (errorMessage.search(/No bonds/gi) > -1) {
        throw new Error("There is no bonds. you can't add farms");
      }
      if (errorMessage.search(/too small amount/gi) > -1) {
        throw new Error(
          "You entered too small amount to the farms. increase the amount!"
        );
      }
      throw error;
    }
  };
