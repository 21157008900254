import AppLink from "Component/AppLink";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Nav } from "types";

export const RenderLink = ({
  nav,
  className = "",
}: {
  nav: Nav;
  className?: string;
}) => {
  const { pathname } = useLocation();
  const active = useMemo(() => {
    return pathname === nav.to || (nav.reg && pathname.search(nav.reg) > -1);
  }, [nav.reg, nav.to, pathname]);

  return nav.externalLink ? (
    <a
      href={nav.externalLink}
      className={`header-app-link ${className}`}
      target="_blank"
      rel="noreferrer"
    >
      {nav.text}
    </a>
  ) : (
    <AppLink
      to={nav.to}
      className={`header-app-link ${className} ${active ? "active" : ""}`}
      data-type="light"
      data-html="true"
      data-class="data-tooltip"
      data-tip={nav.dataTip}
    >
      {nav.text}
    </AppLink>
  );
};
