import { CSSProperties, useMemo } from "react";
import { appThemeConfigs } from "config/app-config";
import useThemeConfigName from "hooks/useThemeConfigName";
import { Nav } from "types";
import { useHistory } from "react-router-dom";

type Props = {
  navs: Nav[];
  className?: string;
  style?: CSSProperties;
};

export const useAppLogoName = (navs: Nav[]) => {
  return "DEREX";
};

export const AppHeaderLogo = ({ navs }: Props) => {
  const logoName = useAppLogoName(navs);
  const history = useHistory();

  const logoUrl = history.location.pathname.toLowerCase().includes("ibo")
    ? "IBOpad"
    : "DEREX";

  return (
    <>
      {logoUrl === "DEREX" ? (
        <img
          src={`/images/${logoUrl}-logo.png`}
          onClick={() => {
            history.push("/");
          }}
          className="cursor-pointer"
          alt={`${logoName} header logo`}
        />
      ) : (
        <a href="https://ibo.derex.exchange/" target="_blank" rel="noreferrer">
          <img
            src={`/images/${logoUrl}-logo.png`}
            className="cursor-pointer"
            alt={`${logoName} header logo`}
          />
        </a>
      )}
    </>
  );
};

export const AppFooterLogo = ({ navs, className = "", style }: Props) => {
  const themeConfigName = useThemeConfigName();
  const logoName = useAppLogoName(navs);

  const logUrl = useMemo(() => {
    if (themeConfigName === "lpInsure") {
      return appThemeConfigs[themeConfigName].footerLogoUri;
    }
    return `/images/${logoName}-footer.png`;
  }, [themeConfigName, logoName]);

  return (
    <img
      src={logUrl}
      alt={`${logoName} footer logo`}
      className={`${className} doodle`}
      style={style}
    />
  );
};

export const AppQonetumFooterLogo = ({ navs }: Props) => {
  const logoName = useAppLogoName(navs);

  return (
    <img
      src={`/images/${logoName}-qonetum-footer-logo.png`}
      alt={`${logoName} qonetum footer logo`}
    />
  );
};
