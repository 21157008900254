import styled from 'styled-components'
import Media from 'Theme/media-breackpoint'
import { FlexDiv } from 'Component/core/elements/others/styled'

export const FooterLeft = styled.div`
  z-index: 1;
  position: relative;
  top: 38px;
  .doodle {
    position: absolute;
    left: 0px;
    top: -85px;
    ${Media.sm} {
      width: 75px;
      top: -80px;
    }
    ${Media.lg} {
      width: 100px;
    }
    visibility: visible !important;
  }
  .footer-detail {
    display: flex;
    align-items: center;
    padding-left: 120px;
    img {
      margin-right: 15px;
      width: 23px;
    }
    ${Media.xl} {
      justify-content: center;
      margin-top: -30px;
      margin-bottom: 55px;
      padding-left: 0;
    }
  }
  a {
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.colorPrimary};
    display: flex;
    align-items: center;
    svg {
      margin-left: 15px;
    }
    :hover {
      color: ${(props) => props.theme.colorPrimaryHover};
    }
  }
  ${Media.xl} {
    width: 100%;
  }
`

export const ValueText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colorWhite};
  margin-right: 15px;
`

export const FooterMiddle = styled.div`
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 20px;
  ${Media.xl} {
    margin-right: 20px;
  }
  ${Media.lg} {
    z-index: 51;
    width: 100%;
    margin: 0px 15px;
  }
`

export const FooterMain = styled(FlexDiv)`
  background-color: ${(props) => props.theme.bodybg02};
  padding: 9px 0px;
  justify-content: space-between;

  & a:hover {
    color: ${(props) => props.theme.colorWhite};
  }
  ${FooterLeft} {
    z-index: 51;
   }
   ${FooterMiddle} {
    z-index: 50;
   }
  ${Media.xl}{
   ${FooterLeft} {
    z-index: 50;
   }
   ${FooterMiddle} {
    z-index: 51;
   }
  }
`

export const FooterRight = styled(FlexDiv)`
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  padding-right: 20px;

  ${Media.lg} {
  }

  ${Media.xl} {
    padding-right: 0px;
    position: relative;
    margin-top: 10px;
    width: 100%;
    align-items: center;
  }
`

export const FooterMiddleUpper = styled(FlexDiv)`
  padding-top: 16px;
  padding-bottom: 16px;
  .active {
    color: ${({ theme }) => theme.colorPrimary};
  }
  a {
    font-size: 16px;
    font-family: var(--dx-ff-secondary);
    font-weight: 700;
    color: ${(props) => props.theme.colorLightGrey};
    :hover {
      // color: ${(props) => props.theme.colorPrimary};
    }
    ${Media.xs} {
      display: block;
      line-height: 24px;
      text-align: center;
    }
  }
  span {
    width: 1px;
    height: 15px;
    background-color: ${(props) => props.theme.bodybg06};
    margin: 0px 15px;
    ${Media.xs} {
      display: none;
    }
  }
  ${Media.xs} {
    display: block;
  }
`

export const FooterMiddleLower = styled(FlexDiv)`
  p {
    font-size: 12px;
    font-family: var(--dx-ff-primary);
    font-weight: 400;
    color: ${(props) => props.theme.colorLightGrey};
    margin: 0px 10px 0px 0px;
    ${Media.xs} {
      text-align: center;
    }
  }
  a {
    font-size: 12px;
    color: ${(props) => props.theme.colorLightGrey};
    :hover {
      // color: ${(props) => props.theme.colorPrimary};
    }
    ${Media.xs} {
      display: block;
      line-height: 24px;
      text-align: center;
    }
  }
  span {
    width: 1px;
    height: 15px;
    background-color: ${(props) => props.theme.bodybg06};
    margin: 0px 10px;
    ${Media.xs} {
      display: none;
    }
  }
  ${Media.lg} {
    justify-content: center;
  }
  ${Media.xs} {
    display: block;
  }
`
