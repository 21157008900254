import React from 'react'

function TestPage() {
  return (
    <div>
        <div dir="auto" lang="en"
            className="css-901oao r-18jsvk2 r-37j5jr r-a023e6 r-16dba41 r-rjixqe r-bcqeeo r-bnwqim r-qvutc0"
            id="id__5dwvjrikys4" data-testid="tweetText">
            <span className="r-18u37iz">
                <a dir="ltr" href="/hashtag/BuyBond?src=hashtag_click" role="link"
                    className="css-4rbku5 css-18t94o4 css-901oao css-16my406 r-1cvl2hr r-1loqt21 r-poiln3 r-bcqeeo r-qvutc0">#BuyBond</a>
            </span>
            <span className="css-901oao css-16my406 r-1k78y06 r-bcqeeo r-qvutc0">
                This is a test ad. please ignore this time.
                Invest and gain 2.5% fees.
                chainId: 97
                viewId: 117

                TWBNB
            </span>
        </div>
    </div>
  )
}

export default TestPage